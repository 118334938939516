export const useBase64 = async (file: any) =>
  new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('Imagem não encontrada'))
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = function onError(error) {
      reject(error)
    }
  })
