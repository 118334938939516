import { IoIosArrowDown } from 'react-icons/io'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'

import { useSort } from '@elentari/core'
import { Box, Typography } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'

export const CustomTableCell = withStyles(() => ({
  root: {
    color: '',
    fontWeight: 'bold',
    fontSize: 14
  }
}))(TableCell)

interface TableCellHeadProps {
  label: string
  sort?: string
}

/**
 * @param label word to show in the column
 * @param sort word that will go in the URL for API
 */

export function TableCellHead({ label, sort }: TableCellHeadProps) {
  const [state, { sortBy }] = useSort()

  return (
    <CustomTableCell>
      {sort ? (
        <TableSortLabel
          IconComponent={IoIosArrowDown}
          direction={state.direction}
          onClick={() => sortBy(sort)}
        >
          <Typography>
            <Box fontWeight="fontWeightBold" component="span">
              {label}
            </Box>
          </Typography>
        </TableSortLabel>
      ) : (
        <TableHead>
          <Typography>
            <Box fontWeight="fontWeightBold" component="span">
              {label}
            </Box>
          </Typography>
        </TableHead>
      )}
    </CustomTableCell>
  )
}
