import { Typography, Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { IStatus } from 'types/status'

enum EStatus {
  PENDING = '#CDCDCD',
  DISABLED = '#676767',
  APPROVED = '#4caf50',
  DISAPPROVED = '#c62828',
  ACTIVE = '#4caf50'
}
enum ETranslate {
  PENDING = 'Pendente',
  DISABLED = 'Inativo',
  APPROVED = 'Aprovado',
  DISAPPROVED = 'Reprovado',
  ACTIVE = 'Ativo'
}
export default function Status({ status, bold }: IStatus & { bold?: boolean }) {
  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <div
            style={{
              width: '9px',
              height: '9px',
              backgroundColor: EStatus[status],
              borderRadius: '100px'
            }}
          ></div>
        </Grid>
        <Grid item>
          <Typography variant={bold ? 'h6' : 'body2'}>
            <Box fontWeight={bold ? 'fontWeightBold' : undefined}>
              {ETranslate[status]}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
