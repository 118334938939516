import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { realApi } from 'services/api'
import Card from '../../../components/Card'
import GroupValue from '../../../components/GroupValue'
import { UsersCompanies } from '../types/types'
import { SkeletonList } from './Skeleton'
import { formatPhone, formatCPF, formatCNPJ } from 'utils/formatString'
import { FiCodepen, FiMapPin, FiUser } from 'react-icons/fi'
import Box from '@material-ui/core/Box'
import { Button, Paper, Typography } from '@material-ui/core'
import { IStatus } from 'types/status'
import { useSnackbar } from '@elentari/core'
import Status from 'components/Status'

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15)
  },
  title: {
    color: theme.palette.text.secondary
  },
  fullHeight: {
    height: '100%'
  },
  centerIcon: {
    display: 'flex',
    alignItems: 'center'
  }
}))
export const UserDetails = () => {
  const [companies, setCompanies] = useState<UsersCompanies>()
  const [loading, setLoading] = useState(false)
  const [, snackbarActions] = useSnackbar()
  const { push } = useHistory()
  const params: any = useParams()
  const classes = useStyles()
  useEffect(() => {
    getUsersCOmpanies()
  }, [])
  async function getUsersCOmpanies() {
    const response = await realApi.getUserCompaniesDetails(params.id)
    setCompanies(response.data?.data[0])
  }
  async function updateStatus(status: IStatus['status']) {
    setLoading(true)
    const response: any = await realApi.updateStatus(status, params.id)
    if (response.ok) {
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'O status foi atualizado com sucesso!'
      })
      await getUsersCOmpanies()
    } else {
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: 'Houve um erro ao atualizar o status.'
      })
    }
    setLoading(false)
  }
  if (!companies) {
    return <SkeletonList />
  }
  return (
    <Box marginLeft={1}>
      <Grid container>
        <Grid item xs={12}>
          <Card
            backButton={e =>
              push('/users-companies?sort%5Bstatus%5D=desc&status=PENDING')
            }
            title="INFORMAÇÕES GERAIS"
          >
            <Grid container spacing={4} alignItems="stretch">
              <Grid item xs={6}>
                <Paper elevation={4} className={classes.fullHeight}>
                  <Box p={3}>
                    <Grid
                      spacing={2}
                      container
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs="auto" className={classes.centerIcon}>
                            <FiUser size="20px" />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="h6">
                              <Box fontWeight="fontWeightBold" component="span">
                                USUÁRIO
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item xs>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="Nome"
                                  value={companies?.user?.name}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="CPF"
                                  value={formatCPF(companies?.user.cpf)}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="E-mail"
                                  value={companies?.user.email}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="Celular"
                                  value={formatPhone(companies?.user.phone)}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      color="textSecondary"
                                      variant="caption"
                                      style={{ textTransform: 'uppercase' }}
                                    >
                                      <Box fontWeight="fontWeightBold">
                                        Status
                                      </Box>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Status bold status={companies.status} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={4} className={classes.fullHeight}>
                  <Box p={3}>
                    <Grid spacing={2} container alignItems="center">
                      <Grid item xs="auto" className={classes.centerIcon}>
                        <FiCodepen size="20px" />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="h6">
                          <Box fontWeight="fontWeightBold">EMPRESA</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4} alignItems="center">
                          <Grid item xs>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <GroupValue
                                  title="nome fantasia"
                                  value={companies?.company.nomeFantasia}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="cnpj"
                                  value={formatCNPJ(companies?.company?.cnpj)}
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="telefone"
                                  value={formatPhone(
                                    companies?.company.telefone
                                  )}
                                  disableTooltip
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={4} className={classes.fullHeight}>
                  <Box p={3}>
                    <Grid spacing={2} container alignItems="center">
                      <Grid item xs="auto" className={classes.centerIcon}>
                        <FiMapPin size="20px" />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="h6">
                          <Box fontWeight="fontWeightBold">ENDEREÇO</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={4}>
                          <Grid item xs>
                            <Grid container>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="endereço"
                                  value={
                                    companies?.company.endereco ||
                                    'Não informado'
                                  }
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="bairro"
                                  value={
                                    companies?.company?.bairro ||
                                    'Não informado'
                                  }
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="município"
                                  value={
                                    companies?.company.municipio ||
                                    'Não informado'
                                  }
                                  disableTooltip
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GroupValue
                                  title="uf"
                                  value={
                                    companies?.company.uf || 'Não informado'
                                  }
                                  disableTooltip
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                {companies.status === 'PENDING' && (
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="flex-end"
                    className={classes.fullHeight}
                  >
                    <Grid item xs="auto">
                      <Button
                        onClick={() => updateStatus('DISAPPROVED')}
                        color="inherit"
                        variant="outlined"
                        disabled={loading}
                      >
                        Reprovar vínculo
                      </Button>
                    </Grid>
                    <Box pl={2} />
                    <Grid item xs="auto">
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => updateStatus('APPROVED')}
                        disabled={loading}
                      >
                        Aprovar vínculo
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
