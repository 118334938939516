import Box from '@material-ui/core/Box'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Field, FieldRenderProps } from 'react-final-form'

interface IProps {
  name: string
}

export const Input = ({
  name,
  ...rest
}: IProps & Omit<TextFieldProps, 'fullWidth' | 'size' | 'variant'>) => {
  return (
    <Field name={name}>
      {(props: FieldRenderProps<any, HTMLElement>) => {
        return (
          <>
            <TextField
              size="small"
              variant="outlined"
              {...props.input}
              fullWidth
              {...rest}
            />
            {(props.meta.dirty || props.meta.submitFailed) &&
              props.meta.invalid && (
                <Box marginTop={0.5}>
                  <Typography variant="subtitle2">
                    {props.meta.error}
                  </Typography>
                </Box>
              )}
          </>
        )
      }}
    </Field>
  )
}
