import { makeRepository } from '@elentari/core/utils/makeRepository'
import { Page } from 'utils/types'
import { apiSauceInstance } from '../../../services/api'
import { ICustomer, SaveUserInput } from '../types/types'
import { paginationAndSortParams, feathers } from 'utils/mapQueryParams'
const { ilike } = feathers

export const repository = makeRepository<
  Page<ICustomer>,
  SaveUserInput,
  ICustomer,
  number
>(
  {
    resource: 'users',
    mapQueryParams: (params: any) => {
      return paginationAndSortParams({
        ...params,
        email: ilike(params.email || ''),
        name: ilike(params.name || ''),
        cpf: ilike(params.cpf || '')
      })
    }
  },
  apiSauceInstance
)
