import { AppWrap } from 'components/AppWrap'
import Users from 'modules/users/list'
import { UserMasterDetail } from 'modules/users/details/MasterDetails'
import GroupList from 'modules/group/list'
import { MasterDetail as GroupMasterDetail } from 'modules/group/details/MasterDetail'
import PromotionList from 'modules/promotions/list'
import UsersCompanies from 'modules/userCompanies/list'
import { UserDetails } from 'modules/userCompanies/details'
import { Redirect, Route, Switch } from 'react-router-dom'
import BannersListIndex from 'modules/banners/list/BannersListIndex'
import BannerForm from './../banners/form/BannerForm'
import BannersMasterDetails from 'modules/banners/details/BannersMasterDetails'

const HomeNavigation = () => {
  return (
    <AppWrap
      isPositionButtonMenuDrawer={false}
      onToggleDrawer={() => {}}
      classes={null}
    >
      <div style={{ height: '100%' }}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/users" />
          </Route>
          <Route path="/users" exact component={Users} />
          <Route path="/users/:id" exact component={UserMasterDetail} />
          <Route path="/group" exact component={GroupList} />
          <Route path="/group/:id" exact component={GroupMasterDetail} />
          <Route path="/promotion" exact component={PromotionList} />
          <Route path="/users-companies" exact component={UsersCompanies} />
          <Route path="/users-companies/:id" exact component={UserDetails} />
          <Route path="/banners/add" exact component={BannerForm} />
          <Route path="/banners" exact component={BannersListIndex} />
          <Route path="/banners/:id" exact component={BannersMasterDetails} />
        </Switch>
      </div>
    </AppWrap>
  )
}

export default HomeNavigation
