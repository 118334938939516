import {
  CircularProgress,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { useBase64 } from './../hooks/useBase64'
import EditIcon from '@material-ui/icons/EditOutlined'

type ImageSelectorProps = {
  value?: any
  width?: number | string
  height?: number | string
  onChange: (valueToChange: any) => void
  error?: boolean
  helperText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: (props: {
      height?: number | string
      width?: number | string
      error?: boolean
    }) => props.height || '100%',
    width: (props: {
      height?: number | string
      width?: number | string
      error?: boolean
    }) => props.width || '100%',
    borderRadius: theme.spacing(2),
    border: (props: {
      height?: number | string
      width?: number | string
      error?: boolean
    }) =>
      `1px solid ${
        props.error ? theme.palette.error.main : theme.palette.grey[300]
      }`,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      '& $iconHover': {
        transition: 'opacity 0.5s',
        opacity: 1
      },
      border: `1px solid ${theme.palette.text.secondary}`
    }
  },
  imageInput: {
    display: 'none'
  },
  img: {
    objectFit: 'cover',
    transition: 'filter 0.2s',
    '&:hover': {
      filter: 'blur(2px)'
    }
  },
  icon: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  loading: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip
  },
  iconHover: {
    opacity: 0,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  erroText: {
    color: theme.palette.error.main
  }
}))

const ImageSelector = ({
  value,
  onChange,
  width,
  height,
  error,
  helperText
}: ImageSelectorProps) => {
  const classes = useStyles({ height, width, error })
  const [image, setImage] = useState<any>(value)
  const [loading, setLoading] = useState(false)
  const inputRef: any = useRef(null)
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const file = inputRef.current.files[0]
    if (file) {
      const base64 = await useBase64(file)
      setImage(base64)
      onChange(file)
    }
    setLoading(false)
  }
  return (
    <>
      <div className={classes.root} onClick={() => inputRef.current.click()}>
        {loading && (
          <CircularProgress color="primary" className={classes.loading} />
        )}
        {!loading && (
          <IconButton className={image ? classes.iconHover : classes.icon}>
            {image ? <EditIcon /> : <AddIcon />}
          </IconButton>
        )}
        <img
          src={image || '/banner-proportion.png'}
          alt="imagem-selecionada"
          width="100%"
          height="100%"
          className={classes.img}
        />
      </div>
      {helperText && (
        <Typography variant="caption" className={classes.erroText}>
          {helperText}
        </Typography>
      )}
      <input
        type="file"
        ref={inputRef}
        accept="image/*"
        className={classes.imageInput}
        onChange={e => {
          handleChange(e)
        }}
      />
    </>
  )
}

export default ImageSelector
