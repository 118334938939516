import qs from 'qs'
import { formatISO, parse } from 'date-fns'

const getSort = (name: string) => {
  switch (name) {
    case 'asc':
      return '1'
    case 'desc':
      return '-1'
    default:
      return '0'
  }
}

const formaStringDateToIso = (date: string) =>
  formatISO(parse(date, 'yyyy-MM-dd', new Date()))

export const feathers = {
  ilike: (value: string) => ({
    $iLike: `%${value}%`
  }),
  dateRange: (startDate?: string, finalDate?: string) => {
    if (startDate && !finalDate) {
      return {
        $gte: formaStringDateToIso(startDate)
      }
    }
    if (!startDate && finalDate) {
      return {
        $lte: formaStringDateToIso(finalDate)
      }
    }
    if (finalDate && startDate) {
      return {
        $gte: formaStringDateToIso(startDate),
        $lte: formaStringDateToIso(finalDate)
      }
    }
  }
}

export const paginationAndSortParams = (params: qs.ParsedQs): qs.ParsedQs => {
  const { page = '1', size = '10', sort, ...rest } = params
  let $sort
  if (sort) {
    const [field, sortName] = Object.entries(sort as any)[0] as [string, string]
    $sort = { [field]: getSort(sortName) }
  }
  return {
    ...rest,
    $skip: String((+page - 1) * +size),
    $limit: size,
    $sort
  }
}
