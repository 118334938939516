import { useBanner } from '../hooks/useBanners'
import BannersDetails from './BannersDetails'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  useReplaceFragments,
  mapFragmentReplace
} from '@elentari/core/hooks/useReplaceFragmets'
import SkeletonList from './BannersSkeleton'
import { format } from 'date-fns'

export function BannersMasterDetails() {
  const [state, actions] = useBanner()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      c => String(c.entity.id),
      c => c.entity.title
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return state.tag === 'with-data' ? (
    handleStateErrorsToRender(
      state,
      <BannersDetails {...state.entity} fetch={actions.fetch} />
    )
  ) : (
    <SkeletonList />
  )
}

export default BannersMasterDetails
