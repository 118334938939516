import { useSnackbar } from '@elentari/core'
import { Errors } from 'components/Errors'
import { useEffect, useState } from 'react'
import { useGroupList } from '../hooks/useGroup'
import { repository } from '../repositories/groupsRepository'
import { TableList } from './Table'
import { GroupForm } from './Form'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import ConfirmModal, { ConfirmModalProps } from 'components/ConfirmModal'
import SendNotificationDialog, {
  NotificationDialogProps
} from 'components/SendNotificationDialog'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const List = () => {
  const [state, actions] = useGroupList()
  const [loading, setLoading] = useState(false)
  const [, snackbarActions] = useSnackbar()
  const [openModal, setOpenModal] = useState(false)
  const [modal, setModal] = useState<ConfirmModalProps | undefined>()
  const [requestLoading, setRequestLoading] = useState(false)
  const [notificationDialog, setNotificationDialog] = useState<
    NotificationDialogProps | undefined
  >()
  const theme = useTheme()

  useEffect(() => {
    fetchGroup()
  }, [])

  async function fetchGroup() {
    setLoading(true)
    actions.fetchPage()
    setLoading(false)
  }
  async function handleDelete(id: number) {
    setRequestLoading(true)
    const response = await repository.delete(id)
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      setModal(undefined)
      setRequestLoading(false)
      actions.fetchPage()
      return
    }
    setModal(undefined)
    setRequestLoading(false)
    snackbarActions.setSnackBar({
      message: 'Não é possível apagar pois há registros relacionados!',
      severity: 'warning'
    })
  }
  const handleNotificate = async (id: number) => {}
  switch (state.tag) {
    case 'empty':
      return (
        <Box marginLeft={1}>
          <TableList
            list={[]}
            totalCount={0}
            loading={state.loading}
            onDelete={handleDelete}
            onNotificate={handleNotificate}
          />
        </Box>
      )
    case 'with-data':
      return (
        <Box marginLeft={1}>
          <TableList
            list={state.list}
            fetch={actions.fetchPage}
            totalCount={state.totalCount}
            loading={state.loading}
            onDelete={(id: number) => {
              setModal({
                open: true,
                title: 'Remover grupo?',
                description:
                  'Tem certeza que deseja remover o grupo selecionado?',
                src: '/remove-illustration.png',
                handleConfirm: () => handleDelete(id),
                handleClose: () => setModal(undefined)
              })
            }}
            onNotificate={(id: number) => {
              setNotificationDialog({
                open: true,
                handleClose: () => setNotificationDialog(undefined),
                id
              })
            }}
          />
          <Tooltip title="Adicionar grupo">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => setOpenModal(true)}
              style={{
                position: 'fixed',
                right: theme.spacing(4),
                bottom: theme.spacing(4)
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <GroupForm
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            itemAdded={fetchGroup}
          />
          {modal && <ConfirmModal {...modal} loading={requestLoading} />}
          {notificationDialog && (
            <SendNotificationDialog {...notificationDialog} />
          )}
        </Box>
      )
    case 'error':
      return <Errors stateError={state} />
  }
}

export default List
