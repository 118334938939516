import Skeleton from '@material-ui/lab/Skeleton'
import Spacer from 'components/Spacer'
import Card from 'components/Card'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'initial'
  },
  leftAuto: {
    marginLeft: 'auto'
  }
}))

export const SkeletonList = () => {
  const { push } = useHistory()
  const classes = useStyles()
  return (
    <Box marginLeft={1}>
      <Grid item>
        <Card backButton={e => push('/users')} title="INFORMAÇÕES GERAIS">
          <Grid container>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Spacer y={4} />
      <Grid item>
        <Card className={classes.card} title="EMPRESAS VINCULADAS">
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Skeleton variant="rect" width="100%" height="45px" />
                </Grid>
                <Grid item xs={2}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height="45px"
                    className={classes.leftAuto}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Spacer y={8} />
            <Grid container spacing={2} xs={12}>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12}>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
              <Grid item xs={3}>
                <Skeleton variant="text" width="100%" />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  )
}
