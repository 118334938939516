import {
  Modal,
  Backdrop,
  makeStyles,
  Grid,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export type ConfirmModalProps = {
  open: boolean
  src: string
  title: string
  description: string
  handleConfirm: () => Promise<void> | void
  handleClose: () => void
  loading?: boolean
  confirmText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    position: 'relative'
  },
  backdrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(66, 66, 66,0.6)'
  },
  divModal: {
    maxWidth: '350px',
    position: 'relative'
  },
  imgWrapper: {
    width: '100%',
    height: '200px',
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1) * -1
  },
  img: {
    position: 'absolute',
    top: theme.spacing(10) * -1,
    '-webkit-filter': 'drop-shadow(2px 2px 10px rgba(0,0,0,0.5))',
    filter: 'url(#drop-shadow)',
    '-ms-filter':
      "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')"
  },
  titleSize: {
    fontSize: theme.spacing(4)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonWrapper: {
    position: 'relative'
  }
}))
const ConfirmModal = ({
  open,
  src,
  title,
  description,
  handleConfirm,
  handleClose,
  loading,
  confirmText
}: ConfirmModalProps) => {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop
      }}
      className={classes.modal}
    >
      <div className={classes.divModal}>
        <div className={classes.imgWrapper}>
          <img height="300px" src={src} alt={title} className={classes.img} />
        </div>
        <Paper>
          <Box p={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  color="textPrimary"
                  align="center"
                  className={classes.titleSize}
                >
                  <Box fontWeight="fontWeightBold">{title}</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">{description}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      variant="outlined"
                      fullWidth
                    >
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.buttonWrapper}>
                      <Button
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={loading}
                      >
                        {confirmText || 'Confirmar'}
                      </Button>
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    </Modal>
  )
}

export default ConfirmModal
