import { makeRepository } from '@elentari/core/utils/makeRepository'
import { Page } from 'utils/types'
import { apiSauceInstance } from 'services/api'
import { IGroup, SaveGroupInput } from '../types/types'
import { feathers, paginationAndSortParams } from 'utils/mapQueryParams'
const { ilike } = feathers

export const repository = makeRepository<
  Page<IGroup>,
  SaveGroupInput,
  IGroup,
  number
>(
  {
    resource: 'groups',
    mapQueryParams: (params: any) => {
      return paginationAndSortParams({
        ...params,
        name: ilike(params.name || '')
      })
    }
  },
  apiSauceInstance
)
