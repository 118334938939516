import Skeleton from '@material-ui/lab/Skeleton'
import Spacer from 'components/Spacer'
import Card from 'components/Card'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  roundedSkeleton: {
    borderRadius: '100px'
  },
  skeletonWrapper: {
    marginLeft: 'auto'
  },
  card: {
    overflow: 'initial'
  }
}))

export const SkeletonList = () => {
  const { push } = useHistory()
  const classes = useStyles()
  return (
    <Box marginLeft={1}>
      <Grid item>
        <Card
          backButton={e =>
            push('/users-companies?sort%5Bstatus%5D=desc&status=PENDING')
          }
          title="Informações do usuário"
        >
          <Grid container spacing={2} xs={12}>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Spacer y={4} />
      <Grid item>
        <Card className={classes.card} title="Empresas vinculadas">
          <Grid container spacing={2}>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  )
}
