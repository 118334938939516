import { ApiResponse } from 'apisauce'
import { LoginCredentials } from '../modules/login/types'
import { DataResponseLogin } from './types'

export function fakeResponse<T>(data?: T): ApiResponse<T> {
  return {
    ok: true,
    problem: null,
    originalError: null,
    data
  }
}

const getRoleFake = async (
  id: number
): Promise<ApiResponse<{ ability: string }>> => {
  return {
    ok: true,
    problem: null,
    originalError: null,
    data: {
      ability: '[{"action":["manage"],"subject":["all"]}]'
    }
  }
}

export const fixture = {
  setToken: (token: string) => {},
  ping: async () => fakeResponse({}),
  getRole: getRoleFake,
  uploadFile: async (): Promise<(string | undefined)[]> => [''],
  linkFile: async (): Promise<ApiResponse<{ count: number }>> =>
    fakeResponse({ count: 1 }),
  getFile: () => {},
  getFiles: () => [],
  login: async (
    credentials: LoginCredentials
  ): Promise<ApiResponse<DataResponseLogin, { code: number }>> => {
    if (
      credentials.username === 'elentari' &&
      credentials.password === '1234'
    ) {
      return {
        ok: true,
        problem: null,
        originalError: null,
        data: {
          accessToken: '1234token',
          user: {
            id: '123',
            roleId: 1
          },
          recaptchaResponse: 'POSSIBLE_HUMAN'
        }
      }
    }
    return {
      ok: false,
      problem: 'SERVER_ERROR',
      originalError: {} as any,
      data: {
        code: 401
      }
    }
  },
  async getSettings(): Promise<ApiResponse<any>> {
    return fakeResponse(settings)
  },
  async updateSettings(settings: any): Promise<ApiResponse<any>> {
    return fakeResponse(settings)
  }
}

export const companiesList = [
  {
    id: '1',
    name: 'Itausa',
    valuation: 56,
    createdAt: '2021-04-01',
    active: true,
    email: `teste@teste.com`
  },
  {
    id: '2',
    name: 'Weg',
    valuation: 82,
    createdAt: '2021-04-01',
    active: true,
    email: `teste@teste.com`
  },
  {
    id: '3',
    name: 'Taesa',
    valuation: 120,
    createdAt: '2021-04-01',
    active: false,
    email: `teste@teste.com`
  },
  {
    id: '4',
    name: 'Eletrobras',
    createdAt: '2021-04-01',
    valuation: 31,
    active: false,
    email: `teste@teste.com`
  },
  {
    id: '5',
    name: 'Ambev',
    createdAt: '2021-04-01',
    valuation: 97,
    active: true,
    email: `teste@teste.com`
  }
]

export const contactsList = [
  {
    id: '1',
    name: 'contact 1',
    email: 'email1@gmail.com',
    phone: 'phone1',
    belongsTo: '1',
    isMain: true,
    createdAt: ''
  },
  {
    id: '2',
    name: 'contact 2',
    email: 'email2@gmail.com',
    phone: 'phone2',
    belongsTo: '2',
    isMain: true,
    createdAt: ''
  },
  {
    id: '3',
    name: 'contact 3',
    email: 'email3@gmail.com',
    phone: 'phone3',
    belongsTo: '1',
    isMain: true,
    createdAt: ''
  },
  {
    id: '4',
    name: 'contact 4',
    email: 'email4@gmail.com',
    phone: 'phone4',
    belongsTo: '4',
    isMain: true,
    createdAt: ''
  }
]
export const usersList = [
  {
    id: '1',
    name: 'user 1',
    email: 'email1@gmail.com',
    createdAt: ''
  },
  {
    id: '2',
    name: 'user 2',
    email: 'email2@gmail.com',
    createdAt: ''
  },
  {
    id: '3',
    name: 'user 3',
    email: 'email3@gmail.com',
    createdAt: ''
  },
  {
    id: '4',
    name: 'user 4',
    email: 'email4@gmail.com',
    createdAt: ''
  }
]

export const rolesList = [
  {
    id: 1,
    name: 'Admin',
    ability: JSON.stringify([{ action: ['manage'], subject: ['all'] }])
  },
  {
    id: 2,
    name: 'User',
    ability: JSON.stringify([{ action: ['read'], subject: ['users'] }])
  }
]

const settings = {
  id: 1,
  name: 'main',
  fontSize: 16,
  primaryColor: '#2D3869',
  backgroundColor: '#fff',
  fontColor: '#333',
  pdfFormat: 'A4',
  pdfOrientation: 'portrait',
  minCompanyValuation: 50,
  createdAt: '2021-06-09T16:55:25.265Z',
  updatedAt: '2021-06-09T16:55:25.266Z'
}
