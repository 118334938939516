import Grid from '@material-ui/core/Grid'
import { ReactNode } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  maxWidth: {
    width: 160
  }
}))
export const FormWrap = ({
  children,
  handleSubmit,
  hiddenBackButton,
  disableSubmit = false,
  disableBack = false,
  handleBack
}: {
  children: ReactNode
  handleSubmit: any
  hiddenBackButton?: boolean
  disableBack?: boolean
  disableSubmit?: boolean
  handleBack?: () => void
}) => {
  const classes = useStyles()
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} direction="column">
        <Grid item>{children}</Grid>

        <Grid item xs={12}>
          <Grid justify="flex-end" container spacing={2}>
            {!hiddenBackButton && (
              <Grid item className={classes.maxWidth}>
                <Button
                  fullWidth
                  type="button"
                  variant="outlined"
                  onClick={() => (handleBack ? handleBack() : history.back())}
                  disabled={disableBack}
                >
                  Cancelar
                </Button>
              </Grid>
            )}
            <Grid item className={classes.maxWidth}>
              <Button
                data-testid="salvar"
                fullWidth
                type="submit"
                disabled={disableSubmit}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
