import Error400 from '../assets/400ErrorPage.svg'
import Error500 from '../assets/500ErrorPage.svg'
import { Error } from '@elentari/core/types'
interface Props {
  stateError?: Error & { data: any }
}
export const Errors = ({ stateError }: Props) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img
          src={stateError?.status === 400 ? Error400 : Error500}
          style={{ width: '400px' }}
        />
        <h1
          style={{
            fontFamily: 'sans-serif',
            fontSize: '30px',
            marginTop: stateError?.status === 400 ? '-60px' : '10px',
            color: '#263238'
          }}
        >
          Erro ao carregar página, contate o suporte!
        </h1>
      </div>
    </>
  )
}
