import {
  Button,
  makeStyles,
  Modal,
  Theme,
  Typography,
  IconButton,
  Grid
} from '@material-ui/core'
import Card from 'components/Card'
import { Link } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    color: '#fff'
  },
  card: {
    maxWidth: '35%',
    margin: '80px auto',
    maxHeight: '80%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative'
  },
  helpIcon: {
    marginLeft: '20px'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

type HelpCsvFileCnpjsProps = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
}

export const HelpCsvFileCnpjs = ({
  open,
  handleOpen,
  handleClose
}: HelpCsvFileCnpjsProps) => {
  const classes = useStyles()
  return (
    <Modal open={open} onClose={handleClose}>
      <Card className={classes.card}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs="auto">
                <img
                  src="/help-illustration.png"
                  alt="help image"
                  width="400"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                  Baixe o CSV no botão abaixo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body2">
                  Basta baixar o CSV, inserir o CNPJ na coluna CNPJ e importar
                  no sistema. A estrutura deve ser obedecida para que a
                  importação aconteça corretamente!
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary">
              <Link
                to="/modeloImportacao.csv"
                target="_blank"
                download
                className={classes.link}
              >
                Dowload Csv
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  )
}
