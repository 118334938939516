/* eslint-disable array-callback-return */
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { PublishOutlined, HelpOutlineOutlined } from '@material-ui/icons'
import { TableContainer } from 'components/TableContainer'
import { useRef, useState } from 'react'
import { CSVReader } from 'react-papaparse'
import { HelpCsvFileCnpjs } from './help'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { formatCNPJ } from './../../utils/formatString'
import useSnackbar from '@elentari/core/hooks/useSnackbar'
interface IData {
  data: string[]
}
interface IProps {
  saveValues: (data: string[]) => any
}
const useStyles = makeStyles(theme => ({
  groupButtonContainer: {
    position: 'fixed',
    bottom: '24px',
    right: '24px'
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  checkboxSize: {
    width: theme.spacing(2)
  },
  boxWidth: {
    width: '500px'
  },
  dialogActions: {
    marginTop: theme.spacing(-4)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonWrapper: {
    position: 'relative'
  }
}))
export const ImportCnpjCsv = ({ saveValues }: IProps) => {
  const classes = useStyles()
  const [, snackbarActions] = useSnackbar()
  const [date, setDate] = useState<IData[] | null>(null)
  const [selectedData, setSelectedData] = useState<string[]>([])
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const inputRef: any = useRef()

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false)
  }

  const handleSpeedDialOpen = () => {
    setSpeedDialOpen(true)
  }

  function handleGetFiles(e: any) {
    inputRef.current.open(e)
  }
  function processFile(e: IData[], file: File) {
    const result: IData[] = []
    e.forEach(row => {
      const cnpj = formatCNPJ(row.data[0])
      if (cnpj.length === 18) {
        result.push({ data: [cnpj] })
      }
    })
    if (result.length > 0) {
      return setDate(result)
    }
    snackbarActions.setSnackBar({
      message: 'Seu CSV não possui CNPJs válidos',
      severity: 'warning'
    })
  }

  function handleCheck(cnpj: string, exist: boolean) {
    const verifyCnpjExist = selectedData.filter(e => e !== cnpj)
    if (exist) {
      return setSelectedData(e => verifyCnpjExist)
    }
    return setSelectedData(e => [...e, cnpj])
  }
  function handleCheckAll() {
    const itemsSelected: string[] = []
    date?.map(({ data }) => {
      const [cnpj] = data
      itemsSelected.push(cnpj)
    })
    if (selectedData.length < 1) {
      return setSelectedData(itemsSelected)
    }
    return setSelectedData([])
  }

  const resetImport = () => {
    setDate(null)
    setSelectedData([])
  }

  async function saveCnpj() {
    setLoading(true)
    try {
      const response: any = await saveValues(selectedData)
      if (response === 'error') {
        throw new Error('error')
      }
      resetImport()
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }

  const handleOpenHelp = () => {
    setHelpOpen(true)
  }

  const handleCloseHelp = () => {
    setHelpOpen(false)
  }

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    if (date && date[index].data) {
      const [cnpj] = date[index].data
      const verifyCnpjExist = selectedData.filter(e => e === cnpj)
      const exist = verifyCnpjExist.length > 0

      return (
        <TableRow style={style}>
          <TableCell className={classes.checkboxSize}>
            <Checkbox
              onClick={() => handleCheck(cnpj, exist)}
              checked={exist}
              style={{ maxWidth: '50px' }}
              inputProps={{
                'aria-label': 'select all desserts'
              }}
            />
          </TableCell>
          <TableCell>{date[index].data[0]}</TableCell>
        </TableRow>
      )
    } else {
      return <></>
    }
  }
  return (
    <>
      {!!date && (
        <Dialog open={!!date} onClose={resetImport}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer hideLimit loading={false} totalCount={0}>
                <Table>
                  <TableHead>
                    <TableRow
                      style={{
                        fontWeight: 'bold'
                      }}
                    >
                      <TableCell className={classes.checkboxSize}>
                        <Grid container alignItems="center" spacing={4}>
                          <Grid item xs="auto">
                            <Checkbox
                              checked={selectedData.length > 0}
                              onClick={handleCheckAll}
                              inputProps={{
                                'aria-label': 'select all desserts'
                              }}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            CNPJ
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FixedSizeList
                      height={400}
                      width={250}
                      itemSize={46}
                      itemCount={date.length}
                    >
                      {renderRow}
                    </FixedSizeList>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <DialogActions className={classes.dialogActions}>
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={resetImport}
            >
              Fechar
            </Button>
            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={saveCnpj}
                disabled={loading}
              >
                Importar
              </Button>
              {loading && (
                <CircularProgress
                  color="primary"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}

      <SpeedDial
        ariaLabel="SpeedDial example"
        icon={<SpeedDialIcon />}
        onClose={handleSpeedDialClose}
        onOpen={handleSpeedDialOpen}
        open={speedDialOpen}
        className={classes.speedDial}
        direction="up"
      >
        <SpeedDialAction
          icon={<PublishOutlined />}
          tooltipTitle="Importar CSV"
          onClick={handleGetFiles}
        />
        <SpeedDialAction
          icon={<HelpOutlineOutlined />}
          tooltipTitle="Ajuda com CSV"
          onClick={handleOpenHelp}
        />
      </SpeedDial>

      <HelpCsvFileCnpjs
        open={helpOpen}
        handleClose={handleCloseHelp}
        handleOpen={handleOpenHelp}
      />

      <div style={{ display: 'none', position: 'absolute' }}>
        <CSVReader
          style={{ display: 'none', position: 'absolute' }}
          ref={inputRef}
          onFileLoad={processFile}
          noClick
          noDrag
        />
      </div>
    </>
  )
}
