import { makeRepository } from '@elentari/core/utils/makeRepository'
import { feathers, paginationAndSortParams } from 'utils/mapQueryParams'
import { Page } from 'utils/types'
import { apiSauceInstance } from '../../../services/api'
import { UsersCompanies, SaveUserInput } from '../types/types'

const { ilike } = feathers

export const repository = makeRepository<
  Page<UsersCompanies>,
  SaveUserInput,
  UsersCompanies,
  number
>(
  {
    resource: 'users-companies',
    mapQueryParams: (params: any) => {
      return paginationAndSortParams({
        ...params,
        cnpj: ilike(params.cnpj || '')
      })
    }
  },
  apiSauceInstance
)
