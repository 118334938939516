import Skeleton from '@material-ui/lab/Skeleton'
import Spacer from 'components/Spacer'
import Card from 'components/Card'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  roundedSkeleton: {
    borderRadius: '100px'
  },
  skeletonWrapper: {
    marginLeft: 'auto'
  },
  card: {
    overflow: 'initial'
  }
})

export const SkeletonList = () => {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <>
      <Grid item>
        <Card
          backButton={e => push('/group')}
          title="Adicionar empresa ao grupo"
        >
          <Grid container spacing={2} xs={12}>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="150px" />
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              <Skeleton variant="rect" width="100px" height="100%" />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Spacer y={4} />
      <Grid item>
        <Card className={classes.card} title="Grupo | ...">
          <Grid container spacing={2}>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width="100px" />
                <Skeleton variant="text" width="150px" />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  )
}
