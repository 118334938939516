/* eslint-disable eqeqeq */
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { DrawerContext } from 'context/Drawer.context'
import items from 'modules/home/MenuItems'
import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { ItemIcon, ItemText, List } from './style'

interface IProps {
  mouseOutDrawer: boolean
}
const MenuItems = ({ mouseOutDrawer }: IProps) => {
  const { open } = useContext(DrawerContext)
  const [label, setLabel] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<any>(null)
  useEffect(() => {
    if (mouseOutDrawer) {
      handlePopoverClose()
    }
  }, [mouseOutDrawer])
  const handlePopoverOpen = (event: any, label: string) => {
    setAnchorEl(event.currentTarget)
    setLabel(label)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setLabel('')
  }
  // eslint-disable-next-line no-extra-boolean-cast
  const id = Boolean(anchorEl) ? 'simple-popper' : undefined

  const { push } = useHistory()
  const { pathname } = useLocation()
  return (
    <div>
      {items.map(({ icon: Icon, ...item }) => {
        const [, actualPage] = pathname.split('/')
        const id = uuid()
        const activeItem = actualPage === item.name
        return (
          <div
            key={id}
            onMouseEnter={(event: any) =>
              !activeItem && !open && handlePopoverOpen(event, item.label)
            }
            onMouseLeave={handlePopoverClose}
          >
            <List
              button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handlePopoverClose()
                push(item.pathname)
              }}
              active={activeItem}
            >
              <ItemIcon active={activeItem}>
                <Icon />
              </ItemIcon>
              {open && <ItemText active={activeItem}>{item.label}</ItemText>}
            </List>
          </div>
        )
      })}
      {anchorEl && !open && (
        <Popper
          open={Boolean(anchorEl)}
          id={id}
          placement="right"
          anchorEl={anchorEl.current}
          role={undefined}
          transition
          style={{
            width: 200,
            marginLeft: '65px',
            marginTop: anchorEl.getBoundingClientRect().top,
            zIndex: 1500
          }}
        >
          <Paper variant="outlined">
            <Box p={2}>{label}</Box>
          </Paper>
        </Popper>
      )}
    </div>
  )
}

export default MenuItems
