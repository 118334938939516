import { BannerSegment } from 'types/generalTypes'
import { IStatus } from 'types/status'

export interface IBannerItem {
  bannerId: number
  createdAt: Date
  deletedAt: null
  id: number
  section?: {
    codigoSecao: number
    descricao: string
  }
  sectionId?: number
  product?: {
    codigoProduto: number
    descricao: string
    estoque: number
    precoFinal: number
    precoLiquido: number
    valorST: number
  }
  productId: number
  updatedAt: Date
}
export interface IBanner {
  id: number
  createdAt: Date
  updatedAt: Date
  deletedAt: null
  title: string
  bannerUrl: string
  segment: BannerSegment['segment']
  type: 'PRODUCTS' | 'SECTIONS' | 'WARNING'
  active: boolean
  finalDate: string
  initialDate: string
  sequence: string
  items: IBannerItem[]
}

export enum IBannerSegmentTranslate {
  FOOD = 'Comida',
  FARMA = 'Farmacêutico',
  AMBOS = 'Ambos'
}

export enum IBannerTypeTranslate {
  PRODUCTS = 'Produtos',
  SECTIONS = 'Seções',
  WARNING = 'Avisos'
}
export interface SaveUserInput {
  id?: number
  name: string
  email: string
}
