import blueGrey from '@material-ui/core/colors/blueGrey'
import { styled } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

interface IProps {
  open?: boolean
  theme: Theme
}
const drawerWidth = 240
const drawerClosedWidth = 60

export const DrawerBody = styled('div')(({ open, theme }: IProps) => ({
  background: blueGrey['900'],
  height: '100vh',
  overflowX: 'hidden',
  display: 'flex',
  transition: '.2s',
  width: open ? drawerWidth : drawerClosedWidth,
  borderRight: '0px !important',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  paddingTop: theme.spacing(1.75)
}))
export const DrawerHeader = styled('header')(({ open, theme }: IProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: open ? theme.spacing(2) : '',
  transition: '.5s'
}))
