import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { makeUseList } from 'utils/makeUseList'
import { GroupCompanies } from '../types/types'
import { repository } from '../repositories/groupsCompanies'

export const useGroupCompaniesList = makeUseList<GroupCompanies>(
  repository.fetch
)
export const useGroupCompanies = makeUseFetchOne<GroupCompanies, number>({
  fetch: repository.fetchOne
})
