import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import LinkMD from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useAppBar from '../../hooks/useAppBar'

export default function SimpleBreadcrumb() {
  const location = useLocation()
  const [state, actions] = useAppBar()

  useEffect(() => {
    actions.setPath(location.pathname)
  }, [location.pathname])

  if (!state.lastPath) {
    return null
  }

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {state.linksPaths?.map((item: { path: string; label: string }) => (
        <LinkMD
          variant="subtitle1"
          component={Link}
          color="inherit"
          key={item.path}
          to={item.path}
        >
          {item?.label}
        </LinkMD>
      ))}
      <Typography key={state.lastPath} variant="subtitle1" color="textPrimary">
        {state.lastPath === 'add' ? 'Adicionar' : state.lastPath}
      </Typography>
    </Breadcrumbs>
  )
}
