import TextField from '@elentari/components-eve/final-form/TextField'
import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import Grid from '@material-ui/core/Grid'
import { Form } from 'react-final-form'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import yupValidation from '../../../utils/yupValidation'
import { repository as usersRepository } from '../repositories/groupsRepository'
import { SaveGroupInput, UserFormData } from '../types/types'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card'
import { Input } from 'components/Input'

interface IProps {
  openModal: boolean
  onClose: () => void
  itemAdded: () => void
}
const schema = yup.object().shape({
  name: yup.string().required('Nome do grupo é obrigatório!')
})
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  form: {
    padding: '20px'
  }
}))

export const GroupForm = ({ openModal, onClose, itemAdded }: IProps) => {
  const { saveWithoutRedirect } = useEntitySaver<SaveGroupInput>(
    usersRepository.save
  )
  const classes = useStyles()

  async function handleSubmit(data: UserFormData) {
    const savingData = await saveWithoutRedirect(data)
    itemAdded()
    onClose()
  }

  return (
    <Modal
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Card title="Adicionar grupo">
        <Form
          className={classes.form}
          validate={yupValidation(schema)}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, submitting }) => (
            <FormWrap
              handleSubmit={handleSubmit}
              disableSubmit={submitting}
              disableBack={submitting}
              handleBack={onClose}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Input name="name" label="Nome" />
                </Grid>
              </Grid>
            </FormWrap>
          )}
        </Form>
      </Card>
    </Modal>
  )
}
