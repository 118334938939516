/* eslint-disable react/prop-types */
import React, { CSSProperties } from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Grid from '@material-ui/core/Grid'
import { Button, makeStyles, Theme } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTheme } from '@material-ui/core/styles'
interface Iprops {
  title?: string | React.ReactElement
  style?: CSSProperties
  subtitle?: string
  disabledSaveButton?: boolean
  saveButtonLabel?: string
  colorSideHeaderButton?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'default'
    | undefined
  loadingSideHeaderButton?: boolean
  backButton?: (press: any) => void
  sideHeaderButton?: () => void
  sideButton?: React.ReactElement
  className?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2)
  },
  header: {
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary
  },
  loading: {
    marginLeft: '20px'
  },
  actionButton: {
    marginLeft: 'auto',
    marginRight: '20px'
  }
}))

const CardContainer: React.FC<Iprops> = ({
  children,
  title,
  disabledSaveButton,
  saveButtonLabel,
  subtitle,
  style,
  colorSideHeaderButton,
  loadingSideHeaderButton,
  backButton,
  sideHeaderButton,
  className,
  sideButton
}) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Card elevation={0} style={style} className={className}>
      {title && (
        <Grid container alignItems="center">
          {backButton && (
            <ArrowBack onClick={backButton} className={classes.backButton} />
          )}
          <CardHeader
            title={title}
            subheader={subtitle}
            subheaderTypographyProps={{ color: 'secondary' }}
            className={classes.header}
          />
          {sideHeaderButton && (
            <Button
              className={classes.actionButton}
              data-testid="salvar"
              onClick={sideHeaderButton}
              disabled={disabledSaveButton}
              variant="contained"
              color={colorSideHeaderButton || 'primary'}
              disableElevation
            >
              {saveButtonLabel || 'Salvar'}
              {loadingSideHeaderButton && (
                <CircularProgress
                  color="inherit"
                  size="20px"
                  className={classes.loading}
                />
              )}
            </Button>
          )}
          {sideButton && (
            <div className={classes.actionButton}>{sideButton}</div>
          )}
        </Grid>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export default CardContainer
