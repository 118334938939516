import Link from '@material-ui/icons/Link'
import UnLink from '@material-ui/icons/LinkOff'
import IconButton from '@material-ui/core/IconButton'
import { realApi } from 'services/api'
import GroupValue from '@elentari/components-eve/typography/GroupValue'
import { UserFilter } from './FilterPanel'
import { formatCNPJ, formatCPF } from 'utils/formatString'
import { TableContainer } from 'components'
import Card from 'components/Card'
import Spacer from 'components/Spacer'
import { TableCellHead } from 'components/TableCellHead'
import Status from 'components/Status'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Props } from './types'
import { useHistory } from 'react-router-dom'
import emptyError from 'assets/emptyError.svg'
import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import EmptyListFeedback from 'components/EmptyListFeedback'

export const UsersTable = ({ list, loading, totalCount }: Props) => {
  const { push } = useHistory()

  return (
    <Box marginLeft={1}>
      <UserFilter />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold" component="span">
                    LISTA DE USUÁRIOS PENDENTES PARA VINCULAR A EMPRESA
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Total de usuários: {totalCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer loading={loading} totalCount={totalCount}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="NOME" />
                    <TableCellHead label="CPF" />
                    <TableCellHead label="EMPRESA" />
                    <TableCellHead label="CNPJ" sort="cnpj" />
                    <TableCellHead label="Status" sort="status" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.length > 0 &&
                    list.map(({ id, userId, status, cnpj, user, company }) => {
                      return (
                        <TableRow
                          key={id}
                          hover
                          style={{ cursor: 'pointer' }}
                          onClick={() => push(`users-companies/${id}`)}
                        >
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{formatCPF(user.cpf)}</TableCell>
                          <TableCell>{company.razaoSocial}</TableCell>
                          <TableCell>{formatCNPJ(company.cnpj)}</TableCell>
                          <TableCell>
                            <Status status={status} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {list.length === 0 && !loading && (
          <>
            <EmptyListFeedback
              src={emptyError}
              title="LISTA VAZIA"
              description="Não foram encontrados vínculos de cnpj pendentes."
            />
            <Spacer y={4} />
          </>
        )}
      </Card>
    </Box>
  )
}
