import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useMemo, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { useLocation, useHistory } from 'react-router-dom'
import { realApi } from 'services/api'
import logoImg from 'assets/logo.svg'
import TextField from '../../components/TextField'
import backgroundImage from 'assets/background.png'
import { Box, Typography } from '@material-ui/core'
import { useSnackbar } from '@elentari/core'
import { Input } from 'components/Input'

const isSubmitting = (form: { getState: () => { submitting: boolean } }) =>
  form.getState().submitting
const useStyles = makeStyles(theme => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    padding: 20,
    minWidth: 300
  },
  boxHeight: {
    minHeight: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  logoImg: {
    width: theme.spacing(10),
    height: 'auto',
    position: 'absolute',
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    objectFit: 'cover'
  },
  bg: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionY: '-250px',
    height: '100vh'
  },
  link: {
    color: theme.palette.info.main,
    cursor: 'pointer'
  }
}))

interface Fields {
  password: string
  confirmPass: string
}

export function ResetPassword() {
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const token = query.get('token')
  const [, snackbarActions] = useSnackbar()
  const { push } = useHistory()
  const classes = useStyles()

  const handleSubmit = useCallback(async (formValues: Fields) => {
    if (token) {
      const response: any = await realApi.recoverPass(
        formValues.password,
        token
      )
      if (!response.ok) {
        return snackbarActions.setSnackBar({
          message: response.data.message,
          severity: 'warning'
        })
      }
      snackbarActions.setSnackBar({
        message: 'Senha alterada com sucesso!',
        severity: 'success'
      })
      push('/login')
    }
  }, [])

  const validate = useCallback((values: Fields) => {
    return {
      password: values.password?.trim().length ? undefined : 'Obrigatório',
      confirmPass:
        values.password?.trim().length && values.password === values.confirmPass
          ? undefined
          : 'Obrigatório'
    }
  }, [])

  useEffect(() => {
    if (!token) {
      push('/login')
    }
  }, [])
  return (
    <Grid container>
      <img src={logoImg} alt="Rm" className={classes.logoImg} />
      <Grid item xs={12} sm={4}>
        <Box p={6} className={classes.boxHeight}>
          <Form onSubmit={handleSubmit} validate={validate}>
            {({ handleSubmit, form, error }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <Box fontWeight="fontWeightBold">Redefinir senha</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography color="textSecondary">
                            Redefina sua senha preenchendo os campos
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          label="Nova senha"
                          name="password"
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Confirme sua senha"
                          name="confirmPass"
                          type="password"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end" spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={isSubmitting(form)}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Confirmar
                        </Button>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography
                          className={classes.link}
                          onClick={() => push('/login')}
                        >
                          Voltar
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Box>
      </Grid>
      <Grid xs item className={classes.bg} />
    </Grid>
  )
}
