import { ReactElement } from 'react'
import { FetchState } from '@elentari/core/types'
import { Errors } from '../components/Errors'
export function handleStateErrorsToRender<T>(
  state: FetchState<T>,
  component: ReactElement
): ReactElement {
  if (state.tag === 'error') {
    return <Errors stateError={state} />
  }
  if (state.tag === 'empty-response') {
    return <Errors />
  }

  return component
}
