import BannersListFilter from './BannersListFilter'
import Card from 'components/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableContainer } from 'components'
import { TableCellHead } from 'components/TableCellHead'
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import { Props } from './types.d'
import { useHistory } from 'react-router-dom'
import Status from 'components/Status'
import { addDays, format } from 'date-fns'
import EmptyListFeedback from 'components/EmptyListFeedback'
import Spacer from 'components/Spacer'
import emptyError from 'assets/emptyError.svg'
import { IBannerSegmentTranslate, IBannerTypeTranslate } from '../types/types'
import getDate from 'utils/getDate'

const useStyles = makeStyles(theme => ({
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bannerImg: {
    objectFit: 'cover',
    borderRadius: theme.spacing(2)
  }
}))

const BannersListTable = ({
  list,
  loading,
  totalCount,
  fetch,
  onDelete
}: Props) => {
  const classes = useStyles()
  const { push } = useHistory()
  return (
    <>
      <BannersListFilter />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold" component="span">
                    LISTA DE BANNERS
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Total de banners: {totalCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer loading={loading} totalCount={totalCount}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="" sort="" />
                    <TableCellHead label="Título" sort="title" />
                    <TableCellHead label="Status" sort="active" />
                    <TableCellHead label="Segmento" sort="segment" />
                    <TableCellHead label="Tipo" sort="type" />
                    <TableCellHead label="Início" sort="initialDate" />
                    <TableCellHead label="Fim" sort="finalDate" />
                    <TableCellHead label="Data de criação" sort="createdAt" />
                    <TableCellHead label="" sort="" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(
                    ({
                      title,
                      bannerUrl,
                      active,
                      segment,
                      type,
                      id,
                      initialDate,
                      finalDate,
                      createdAt
                    }) => {
                      return (
                        <>
                          <TableRow
                            key={id}
                            className={classes.tableRow}
                            hover
                            onClick={() => push(`banners/${id}`)}
                          >
                            <TableCell>
                              {bannerUrl && (
                                <img
                                  src={bannerUrl}
                                  alt={`${title}`}
                                  height="50px"
                                  width="100px"
                                  className={classes.bannerImg}
                                />
                              )}
                            </TableCell>
                            <TableCell>{title}</TableCell>
                            <TableCell>
                              <Status status={active ? 'ACTIVE' : 'DISABLED'} />
                            </TableCell>
                            <TableCell>
                              {IBannerSegmentTranslate[segment]}
                            </TableCell>
                            <TableCell>{IBannerTypeTranslate[type]}</TableCell>
                            <TableCell>{getDate.toShow(initialDate)}</TableCell>
                            <TableCell>{getDate.toShow(finalDate)}</TableCell>
                            <TableCell>
                              {format(new Date(createdAt), 'dd/MM/yyyy')}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Deletar">
                                <IconButton
                                  size="small"
                                  aria-label="Deletar"
                                  onClick={e => {
                                    e.stopPropagation()
                                    if (onDelete) onDelete(id)
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!list.length && !loading && (
              <>
                <EmptyListFeedback
                  src={emptyError}
                  title="LISTA VAZIA"
                  description="Não encontramos nenhum banner!"
                />
                <Spacer y={4} />
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default BannersListTable
