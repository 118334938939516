import React, { useState } from 'react'
import Card from 'components/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableContainer } from 'components'
import { TableCellHead } from 'components/TableCellHead'
import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'
import EmptyListFeedback from 'components/EmptyListFeedback'
import Spacer from 'components/Spacer'
import emptyError from 'assets/emptyError.svg'
import { realApi } from 'services/api'
import { useSnackbar } from '@elentari/core'
import ConfirmModal, { ConfirmModalProps } from 'components/ConfirmModal'
import { IBanner, IBannerItem } from '../types/types'

type ItemsListProps = {
  items: IBannerItem[]
  type: IBanner['type']
  fetch: () => void
}

const ItemsList = ({ items, type, fetch }: ItemsListProps) => {
  const [, snackbarActions] = useSnackbar()
  const [modal, setModal] = useState<ConfirmModalProps>()
  const [requestLoading, setRequestLoading] = useState(false)

  const deleteItem = async (itemId: number) => {
    setRequestLoading(true)
    const response: any =
      type === 'PRODUCTS'
        ? await realApi.deleteBannerProductItem(itemId)
        : await realApi.deleteBannerSectionItem(itemId)
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Seu item foi removido com sucesso!',
        severity: 'success'
      })
    } else {
      snackbarActions.setSnackBar({
        message: response.data.message,
        severity: 'warning'
      })
    }
    await fetch()
    setRequestLoading(false)
    setModal(undefined)
  }

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer loading={false} totalCount={0}>
            <Table>
              <TableHead>
                {type === 'PRODUCTS' ? (
                  <TableRow>
                    <TableCellHead label="Descrição" sort="" />
                    <TableCellHead label="Preço" sort="" />
                    <TableCellHead label="Estoque" sort="" />
                    <TableCellHead label="" sort="" />
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCellHead label="Seção" sort="" />
                    <TableCellHead label="" sort="" />
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {items.map(item => {
                  return (
                    <>
                      {type === 'PRODUCTS' && item.product ? (
                        <TableRow key={item.id} hover>
                          <TableCell>{item.product.descricao}</TableCell>
                          <TableCell>
                            R$ {item.product.precoFinal.toFixed(2)}
                          </TableCell>
                          <TableCell>{item.product.estoque}</TableCell>
                          <TableCell>
                            <Tooltip title="Deletar">
                              <IconButton
                                size="small"
                                aria-label="Deletar"
                                onClick={() =>
                                  setModal({
                                    open: true,
                                    title: 'Remover item do banner?',
                                    description:
                                      'Tem certeza que deseja remover o item do  banner?',
                                    src: '/remove-illustration.png',
                                    handleConfirm: () => deleteItem(item.id),
                                    handleClose: () => setModal(undefined)
                                  })
                                }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow hover>
                          <TableCell>{item.section?.descricao}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Deletar">
                              <IconButton
                                size="small"
                                aria-label="Deletar"
                                onClick={() =>
                                  setModal({
                                    open: true,
                                    title: 'Remover item do banner?',
                                    description:
                                      'Tem certeza que deseja remover o item do  banner?',
                                    src: '/remove-illustration.png',
                                    handleConfirm: () => deleteItem(item.id),
                                    handleClose: () => setModal(undefined)
                                  })
                                }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!items.length && (
            <>
              <EmptyListFeedback
                src={emptyError}
                title="LISTA VAZIA"
                description="Não encontramos nenhum item!"
              />
              <Spacer y={4} />
            </>
          )}
          {modal && <ConfirmModal {...modal} loading={requestLoading} />}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ItemsList
