import { useGroup } from '../hooks/useGroup'
import { useAppBar } from 'hooks'
import { handleStateErrorsToRender } from 'utils/handleFetchStateError'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  useReplaceFragments,
  mapFragmentReplace
} from '@elentari/core/hooks/useReplaceFragmets'
import { GroupDetails } from '.'
import { SkeletonList } from './Skeleton'
export function MasterDetail() {
  const [state, actions] = useGroup()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      c => String(c.entity.id),
      c => c.entity.name
    )
  ])

  useFetchAndReset(actions.fetch, actions.reset)
  return state.tag === 'with-data' ? (
    handleStateErrorsToRender(
      state,
      <GroupDetails {...state.entity} fetch={actions.fetch} />
    )
  ) : (
    <SkeletonList />
  )
}
