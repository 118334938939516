import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import { realApi } from 'services/api'
import { Input } from 'components/Input'
import logoImg from 'assets/logo.svg'
import backgroundImage from 'assets/background.png'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Box, IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { useSnackbar } from '@elentari/core'

const isSubmitting = (form: { getState: () => { submitting: boolean } }) =>
  form.getState().submitting

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionY: '-250px',
    height: '100vh'
  },
  boxHeight: {
    minHeight: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  logoImg: {
    width: theme.spacing(10),
    height: 'auto',
    position: 'absolute',
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    objectFit: 'cover'
  }
}))

type Fields = {
  email: string
}

export function RecoverPassword() {
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const history = useHistory()

  const handleSubmit = useCallback(async (formValues: Fields) => {
    const response: any = await realApi.sendRecoverPassEmail(formValues.email)
    if (response.ok) {
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'Um e-mail foi enviado!'
      })
      history.push('/login')
    } else {
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: 'Houve um erro ao enviar e-mail.'
      })
    }
  }, [])

  const validate = useCallback((values: { email?: string }) => {
    return {
      email: values.email?.trim().length
        ? undefined
        : 'Preencha para poder enviar'
    }
  }, [])

  return (
    <Grid container>
      <img src={logoImg} alt="Rm" className={classes.logoImg} />
      <Grid item xs={12} sm={4}>
        <Box p={6} className={classes.boxHeight}>
          <Form onSubmit={handleSubmit} validate={validate}>
            {({ handleSubmit, form, error }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="space-between" spacing={1}>
                      <Grid item xs="auto">
                        <Typography variant="h5">
                          <Box fontWeight="fontWeightBold">Recuperar senha</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        <IconButton
                          size="small"
                          onClick={() => history.push('/login')}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="textSecondary">
                          Digite o e-mail da sua conta para que enviemos as
                          instruções para recuperar a senha
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Input label="E-mail" name="email" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting(form)}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Recuperar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Box>
      </Grid>
      <Grid xs item className={classes.bg} />
    </Grid>
  )
}
