import { ReactNode } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Theme, withStyles } from '@material-ui/core/styles'
import Alert, { Color } from '@material-ui/lab/Alert'

type Props = {
  classes: { close: string }
  message: string
  onClose: () => void
  severity?: Color
  action?: ReactNode[]
}

function CustomSnackbar({
  classes,
  message,
  action = [],
  onClose,
  severity = 'info',
  ...props
}: Props) {
  return (
    <Snackbar
      {...props}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={!!message}
      action={[
        ...action,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ]}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={onClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

const styles = (theme: Theme) => ({
  close: {
    padding: theme.spacing(1 / 2)
  }
})

export default withStyles(styles)(CustomSnackbar)
