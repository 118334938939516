import { Ability, AbilityBuilder, createAliasResolver } from '@casl/ability'
import { User } from './types'

const detectSubjectType = (object: any) => {
  return typeof object === 'string' ? object : object.__typename
}

const resolveAction = createAliasResolver({
  read: ['find', 'get']
})

export const abilityFor = (user: User, roleJson: string): Ability => {
  if (roleJson.length) {
    return new Ability(JSON.parse(roleJson), {
      detectSubjectType,
      resolveAction
    })
  }

  const { can, rules } = new AbilityBuilder(Ability)
  can('read', 'all')
  return new Ability(rules, { detectSubjectType: object => object.__typename })
}
