import { useSnackbar } from '@elentari/core'
import { Button, IconButton, Theme, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ConfirmModal, { ConfirmModalProps } from 'components/ConfirmModal'
import GroupsAutocomplete, {
  GroupsOptions
} from 'components/GroupsAutocomplete'
import GroupValue from 'components/GroupValue'
import { ImportCnpjCsv } from 'components/importCnpjCsv'
import Snackbar from 'components/Snackbar'
import Spacer from 'components/Spacer'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { realApi } from 'services/api'
import { formatCPF, formatPhone } from 'utils/formatString'
import { ICompany, ICustomer } from '../types/types'
import { SkeletonList } from './Skeleton'
import UsersCompanies from '../list/usersCompaniesTable/index'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useUsersCompaniesList } from '../hooks/useFetchUsersCompanies'

interface IProps {
  fetch: (id: number) => void
}

export const UserDetails = ({
  cpf,
  email,
  id,
  name,
  phone,
  status,
  fetch
}: ICustomer & IProps) => {
  const [, actions] = useUsersCompaniesList()
  const [companies, setCompanies] = useState<ICompany[]>()
  const [totalCompanies, setTotalCompaines] = useState(0)
  const [snackbarState, snackbarActions] = useSnackbar()
  const [hintToLinkCompanies, setHintToLinkCompanies] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const { push } = useHistory()

  const [passwordChangeConfirm, setPasswordChangeConfirm] =
    useState<ConfirmModalProps | null>()
  const [toggleUserStatusConfirm, setToggleUserStatusConfirm] =
    useState<ConfirmModalProps | null>()
  const [requestLoading, setRequestLoading] = useState(false)

  async function handleGetCompanies(value: string) {
    const response = await realApi.getCompanies(value, 100)
    if (response.ok && response.data) {
      const dataTreatment = response.data.map(item => ({
        label: item.nomeFantasia,
        value: item.cnpj
      }))
      return dataTreatment
    }
    snackbarActions.setSnackBar({
      message: 'Houve um erro ao carregar os dados, contate o suporte!',
      severity: 'warning'
    })
    return [{ label: '', value: '' }]
  }

  async function getUserCompanie() {
    setLoading(true)
    const response: any = await realApi.getUserCompanies(id)
    setCompanies(response.data.data)
    setTotalCompaines(response.data.total)
    setLoading(false)
  }

  useEffect(() => {
    getUserCompanie()
    handleGetCompanies('')
  }, [])

  async function sendEmail() {
    const response = await realApi.sendRecoverPassEmail(email)
    if (response.ok) {
      setPasswordChangeConfirm(null)
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'E-mail enviado com sucesso!'
      })
    } else {
      setPasswordChangeConfirm(null)
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: 'Houve um erro ao enviar o e-mail!'
      })
    }
    setRequestLoading(false)
  }
  function recoveryPass() {
    setPasswordChangeConfirm({
      title: 'Solicitar troca',
      description:
        'Tem certeza que deseja enviar um e-mail de recuperação de senha?',
      open: true,
      handleClose: () => setPasswordChangeConfirm(null),
      handleConfirm: () => {
        setRequestLoading(true)
        sendEmail()
      },
      src: '/password-change-illustration.png'
    })
  }
  async function handleSubmit(companies: GroupsOptions[]) {
    if (!saving) {
      setSaving(true)
      try {
        const treatmentSelectedCompaniesArr = companies.map(
          ({ value }) => value
        )
        const response: any = await realApi.linkCompanies(
          id,
          treatmentSelectedCompaniesArr
        )
        if (!response.ok) {
          throw new Error(JSON.stringify(response.data))
        }
        if (response.data.occurrences.length) {
          snackbarActions.setSnackBar({
            message: `Alguns CNPJ's não foram adicionados ou por já estarem adicionados ou por não existirem!`,
            severity: 'warning'
          })
        }
        getUserCompanie()
        snackbarActions.setSnackBar({
          message: 'Nova empresa vinculada ao usuário',
          severity: 'success'
        })
        setSaving(false)
        return response.ok
      } catch (err: any) {
        const converErro: any = JSON.parse(err.message)
        snackbarActions.setSnackBar({
          message: converErro.message,
          severity: 'error'
        })
        setSaving(false)
      }
    }
    return false
  }
  async function handleSaveMultipleValues(cnpjGroup: string[]) {
    try {
      const response: any = await realApi.linkCompanies(id, cnpjGroup)
      if (!response.ok) {
        throw new Error(JSON.stringify(response.data))
      }
      if (response.data.occurrences.length) {
        snackbarActions.setSnackBar({
          message: `Alguns CNPJ's não foram adicionados ou por já estarem adicionados ou por não existirem!`,
          severity: 'warning'
        })
        getUserCompanie()
      } else {
        getUserCompanie()
        snackbarActions.setMessage('CSV de empresas, vinculado com sucesso!')
      }
      return 'ok'
    } catch (err: any) {
      const converErro: any = JSON.parse(err.message)
      snackbarActions.setMessage(converErro.message)
      return 'error'
    }
  }
  const changeUserStatus = async (status: ICustomer['status']) => {
    const res: any = await realApi.updateUserStatus(id, status)
    if (res.ok) {
      setHintToLinkCompanies(res.data.status === 'APPROVED')
      setToggleUserStatusConfirm(null)
      snackbarActions.setSnackBar({
        message: 'Usuário atualizado com sucesso',
        severity: 'success'
      })
      fetch(id)
      actions.fetchPage()
    } else {
      setToggleUserStatusConfirm(null)
      snackbarActions.setSnackBar({
        message:
          'Houve um erro ao atualizar o usuário, tente novamente mais tarde.',
        severity: 'warning'
      })
    }
    setRequestLoading(false)
  }

  const toggleUserStatus = () => {
    setToggleUserStatusConfirm({
      title: status === 'APPROVED' ? 'Inativar usuário' : 'Ativar Usuário?',
      description: `Tem certeza que deseja ${
        status === 'APPROVED' ? 'inativar' : 'ativar'
      } este usuário?`,
      open: true,
      handleClose: () => setToggleUserStatusConfirm(null),
      handleConfirm: () => {
        setRequestLoading(true)
        status === 'APPROVED'
          ? changeUserStatus('DISABLED')
          : changeUserStatus('APPROVED')
      },
      src:
        status === 'APPROVED'
          ? '/disable-illustration.png'
          : '/approve-illustration.png'
    })
  }

  if (loading) {
    return <SkeletonList />
  }

  return (
    <Box marginLeft={1}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs="auto">
                  <IconButton onClick={() => push('/users')}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xs="auto">
                  <Typography color="textSecondary" variant="subtitle2">
                    <Box fontWeight="fontWeightBold">INFORMAÇÕES GERAIS</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <GroupValue title="Nome" value={name} disableTooltip />
                </Grid>
                <Grid item xs>
                  <GroupValue
                    title="CPF"
                    value={formatCPF(cpf)}
                    disableTooltip
                  />
                </Grid>
                <Grid item xs>
                  <GroupValue
                    title="Telefone"
                    value={formatPhone(phone)}
                    disableTooltip
                  />
                </Grid>
                <Grid item xs>
                  <GroupValue title="E-mail" value={email} disableTooltip />
                </Grid>
                <Grid xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={recoveryPass}
                        disableElevation
                        fullWidth
                      >
                        trocar senha
                      </Button>
                    </Grid>

                    <Grid item xs={6}>
                      {status === 'APPROVED' ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggleUserStatus}
                          disableElevation
                          fullWidth
                        >
                          Inativar
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={toggleUserStatus}
                          disableElevation
                          fullWidth
                        >
                          Ativar
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Spacer y={8} />
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    <Box fontWeight="fontWeightBold">EMPRESAS VINCULADAS</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <GroupsAutocomplete
                    buttonTitle="Salvar"
                    onFormSubmit={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <UsersCompanies />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ImportCnpjCsv saveValues={e => handleSaveMultipleValues(e)} />
      <Snackbar
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={() => snackbarActions.setMessage('')}
      />
      {passwordChangeConfirm && (
        <ConfirmModal {...passwordChangeConfirm} loading={requestLoading} />
      )}
      {toggleUserStatusConfirm && (
        <ConfirmModal {...toggleUserStatusConfirm} loading={requestLoading} />
      )}
      {hintToLinkCompanies && (
        <ConfirmModal
          open={hintToLinkCompanies}
          src="/link-illustration.png"
          handleClose={() => setHintToLinkCompanies(false)}
          handleConfirm={() => setHintToLinkCompanies(false)}
          title="Refazer vínculos"
          description="O usuário foi reativado mas os seus vínculos precisam ser refeitos!"
          loading={false}
          confirmText="Entendi"
        />
      )}
    </Box>
  )
}
