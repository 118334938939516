import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useHistory } from 'react-router-dom'
import { formatCPF, formatPhone } from 'utils/formatString'
import { TableContainer } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card'
import Spacer from 'components/Spacer'
import { TableCellHead } from 'components/TableCellHead'
import { Props } from './types'
import { FilterPanel } from './FilterPanel'
import Typography from '@material-ui/core/Typography'
import EmptyListFeedback from 'components/EmptyListFeedback'
import emptyError from 'assets/emptyError.svg'
import { Box, Grid } from '@material-ui/core'
import Status from 'components/Status'

const useStyles = makeStyles(theme => ({
  tabeleRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export const TableList = ({ list, loading, totalCount }: Props) => {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Box marginLeft={1}>
      <FilterPanel />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold" component="span">
                    LISTA DE USUÁRIOS
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="subtitle2">
                  Total de usuários: {totalCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer loading={loading} totalCount={totalCount}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Nome" sort="name" />
                    <TableCellHead label="Status" sort="status" />
                    <TableCellHead label="E-mail" sort="email" />
                    <TableCellHead label="CPF" sort="cpf" />
                    <TableCellHead label="Telefone" sort="phone" />
                    <TableCellHead label="Cadastro" sort="createdAt" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.length > 0 &&
                    list.map(user => (
                      <TableRow
                        key={user.id}
                        className={classes.tabeleRow}
                        hover
                        onClick={() =>
                          push(`/users/${user.id}?userId=${user.id}`)
                        }
                      >
                        <TableCell>{user.name}</TableCell>
                        <TableCell>
                          <Status status={user.status} />
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{formatCPF(user.cpf)}</TableCell>
                        <TableCell>{formatPhone(user.phone)}</TableCell>
                        <TableCell>
                          {new Date(user.createdAt).toLocaleDateString()}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {list.length === 0 && !loading && (
          <>
            <EmptyListFeedback
              src={emptyError}
              title="LISTA VAZIA"
              description="Não encontramos nenhum usuário!"
            />
            <Spacer y={4} />
          </>
        )}
      </Card>
    </Box>
  )
}
