import { useSnackbar } from '@elentari/core'
import { Errors } from 'components/Errors'
import { useEffect } from 'react'
import { useUsersList } from '../hooks/useFetch'
import { TableList } from './Table'

const Users = () => {
  const [state, actions] = useUsersList()
  const [, snackbarActions] = useSnackbar()

  async function fetchCustomers() {
    actions.fetchPage()
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  switch (state.tag) {
    case 'empty':
      return <TableList list={[]} totalCount={0} loading={state.loading} />
    case 'with-data':
      return (
        <TableList
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
        />
      )
    case 'error':
      return <Errors stateError={state} />
  }
}

export default Users
