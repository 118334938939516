import { useUser } from '../hooks/useFetch'
import { UserDetails } from './index'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  useReplaceFragments,
  mapFragmentReplace
} from '@elentari/core/hooks/useReplaceFragmets'
import { SkeletonList } from './Skeleton'

export function UserMasterDetail() {
  const [state, actions] = useUser()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      state,
      c => String(c.entity.id),
      c => c.entity.name
    )
  ])
  useFetchAndReset(actions.fetch, actions.reset)
  return state.tag === 'with-data' ? (
    handleStateErrorsToRender(
      state,
      <UserDetails {...state.entity} fetch={actions.fetch} />
    )
  ) : (
    <SkeletonList />
  )
}
