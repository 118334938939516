import { format, addDays } from 'date-fns'

const forField = (date: Date | string) =>
  format(new Date(addDays(new Date(date), 1)), 'yyyy-MM-dd')

const toShow = (date: Date | string) =>
  format(new Date(addDays(new Date(date), 1)), 'dd/MM/yyyy')

export default {
  forField,
  toShow
}
