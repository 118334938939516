import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import { DrawerContext } from 'context/Drawer.context'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { FiMenu } from 'react-icons/fi'
import useAppBar from '../../hooks/useAppBar'
import When from '../When'
import styles from './AppWrapStyles'
import Drawer from './Drawer'
import SimpleBreadcrumb from './SimpleBreadcrumb'
import UserAvatar from './UserAvatar'

const useStyles = makeStyles(styles as any)

const enumMenu = {
  OPEN: 'open',
  CLOSE: 'close'
}

interface AppWrapProps {
  children: ReactNode
  isPositionButtonMenuDrawer: boolean
  onToggleDrawer: (open: string) => void
  classes: any
  customBar?: ReactNode
}

const AppWrap = ({
  children,
  isPositionButtonMenuDrawer = false,
  onToggleDrawer,
  classes: appWrapClasses,
  customBar
}: AppWrapProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const [hideMenu, setHideMenu] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [appBarState, appBarActions] = useAppBar()
  const { open, setOpen } = useContext(DrawerContext)

  const updateDimensions = () => {
    if (window.innerWidth < theme.breakpoints.values.md) {
      setOpen(false)
    }
    setWidth(window.innerWidth)
  }

  const handleToggleDrawer = (open: boolean) => {
    onToggleDrawer(open ? enumMenu.OPEN : enumMenu.CLOSE)
  }

  useEffect(() => {
    setHideMenu(width < theme.breakpoints.values.sm && !open)
  }, [open, width])

  useEffect(() => {
    setOpen(localStorage.toggleDrawer === '1')
    handleToggleDrawer(localStorage.toggleDrawer === '1')
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const toggleMenu = () => {
    setOpen(!open)
    handleToggleDrawer(!open)
    localStorage.setItem('toggleDrawer', !open ? '1' : '0')
  }

  const ButtonMenu = () => (
    <Tooltip
      title={open ? 'Retrair menu' : 'Expandir menu'}
      aria-label={open ? 'Retrair menu' : 'Expandir menu'}
    >
      <IconButton
        aria-label="open drawer"
        onClick={toggleMenu}
        className={clsx(
          classes.menuButton,
          isPositionButtonMenuDrawer && classes.menuButtonInMenuDrawer,
          appWrapClasses && appWrapClasses.menuButton
        )}
      >
        <FiMenu />
      </IconButton>
    </Tooltip>
  )

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <Drawer />
        <AppBar
          className={clsx(
            classes.appBar,
            open && classes.appBarShift,
            hideMenu && classes.appBarHiddenMenu
          )}
          position="absolute"
          elevation={0}
        >
          <Toolbar disableGutters classes={{ root: classes.navBarRoot }}>
            <Grid container>
              <Grid
                item
                xs
                className={clsx(
                  classes.toolsLeft,
                  isPositionButtonMenuDrawer &&
                    classes.toolsLeftWithButtonInDrawer,
                  !hideMenu && classes.toolsLeftHideMenu
                )}
              >
                <ButtonMenu />
                <When value={appBarState.showBreadcrumb} equals={true}>
                  <SimpleBreadcrumb />
                </When>
              </Grid>

              <When value={!!customBar} equals={true}>
                <Grid item xs className={clsx(classes.toolsRight)}>
                  {customBar}
                </Grid>
              </When>

              <Grid item className={clsx(classes.toolsRight)}>
                <UserAvatar />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <main
          className={clsx(
            classes.content,
            appWrapClasses && appWrapClasses.content
          )}
        >
          <div>{children}</div>
        </main>
      </div>
    </div>
  )
}

export default AppWrap
