import { Box, Fab, Tooltip, useTheme } from '@material-ui/core'
import { Errors } from 'components/Errors'
import { useBannersList } from '../hooks/useBanners'
import BannersListTable from './BannersListTable'
import { useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'
import ConfirmModal, { ConfirmModalProps } from 'components/ConfirmModal'
import { repository } from '../repositories/bannersRepository'
import { useSnackbar } from '@elentari/core'

const BannersListIndex = () => {
  const [state, actions] = useBannersList()
  const [modal, setModal] = useState<ConfirmModalProps | undefined>()
  const { push } = useHistory()
  const [requestLoading, setRequestLoading] = useState(false)
  const theme = useTheme()
  const [, snackbarActions] = useSnackbar()

  async function handleDelete(id: number) {
    setRequestLoading(true)
    const response: any = await repository.delete(id)
    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      setModal(undefined)
      setRequestLoading(false)
      actions.fetchPage()
      return
    }
    setModal(undefined)
    setRequestLoading(false)
    snackbarActions.setSnackBar({
      message: response.data.errors[0].message,
      severity: 'warning'
    })
  }

  switch (state.tag) {
    case 'empty':
      return (
        <Box marginLeft={1}>
          <BannersListTable list={[]} totalCount={0} loading={state.loading} />
        </Box>
      )
    case 'with-data': {
      return (
        <Box marginLeft={1}>
          <BannersListTable
            list={state.list}
            fetch={actions.fetchPage}
            totalCount={state.totalCount}
            loading={state.loading}
            onDelete={(id: number) => {
              setModal({
                open: true,
                title: 'Remover banner?',
                description:
                  'Tem certeza que deseja remover o banner selecionado?',
                src: '/remove-illustration.png',
                handleConfirm: () => handleDelete(id),
                handleClose: () => setModal(undefined)
              })
            }}
          />
          <Tooltip title="Adicionar banner">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => push('/banners/add')}
              style={{
                position: 'fixed',
                right: theme.spacing(4),
                bottom: theme.spacing(4)
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          {modal && <ConfirmModal {...modal} loading={requestLoading} />}
        </Box>
      )
    }
    case 'error': {
      return <Errors stateError={state} />
    }
  }
}

export default BannersListIndex
