import {
  Box,
  InputAdornment,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import backgroundImage from 'assets/background.png'
import logoImg from 'assets/logo.svg'
import { Input } from 'components/Input'
import { useCallback, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { FiEye, FiEyeOff, FiLock, FiUser } from 'react-icons/fi'
import { Redirect, useHistory } from 'react-router-dom'
import { debug } from 'utils/debug'
import { realApi } from '../../services/api'
import { AuthContext } from './AuthProvider'
import { LoginCredentials } from './types'
import * as yup from 'yup'
import yupValidation from 'utils/yupValidation'
import { useSnackbar } from '@elentari/core'
const isSubmitting = (form: { getState: () => { submitting: boolean } }) =>
  form.getState().submitting

const useStyles = makeStyles(theme => ({
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.primary.main
  },
  bg: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionY: '-250px',
    height: '100vh'
  },
  logoImg: {
    width: theme.spacing(10),
    height: 'auto',
    position: 'absolute',
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(6),
    objectFit: 'cover'
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.info.main
  },
  boxHeight: {
    minHeight: '100vh',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  }
}))

const Login = () => {
  const history = useHistory()
  const [, snackbarActions] = useSnackbar()
  const classes = useStyles()
  const context = useContext(AuthContext)
  const [emailFocus, setEmailFocus] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const schema = yup.object().shape({
    username: yup
      .string()
      .email('O formato de E-mail está incorreto')
      .required('E-mail é obrigatório!'),
    password: yup.string().required('Senha é obrigatório!')
  })
  const handleSubmit = useCallback(
    async (credentials: LoginCredentials) => {
      try {
        const response = await realApi.login(credentials)

        if (!response.ok) {
          response.data?.code === 401
            ? snackbarActions.setSnackBar({
                severity: 'warning',
                message: 'Usuário ou senha inválidos'
              })
            : snackbarActions.setSnackBar({
                severity: 'warning',
                message:
                  'Houve um erro ao tentar fazer login, tente novamente mais tarde'
              })
          return
        }
        if (context.initialized && response.ok) {
          context.handleUserLogin({
            name: credentials.username ?? '',
            id: response.data?.user.id as string,
            token: response.data?.accessToken as string,
            roleId: Number(response.data?.user.roleId)
          })
        }
      } catch (e) {
        debug(e)
      }
    },
    [context.initialized]
  )

  const handleRecover = useCallback(() => {
    history.push('/recover-pass')
  }, [])

  if (context.initialized && context.state.loggedin) {
    return <Redirect to="/" />
  }

  return (
    <Grid container>
      <img src={logoImg} alt="Rm" className={classes.logoImg} />
      <Grid item xs={12} sm={4}>
        <Box p={6} className={classes.boxHeight}>
          <Form onSubmit={handleSubmit} validate={yupValidation(schema)}>
            {({ handleSubmit, form, error }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          <Box fontWeight="fontWeightBold">
                            Bem vindo ao Backoffice
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography color="textSecondary">
                            Gerenciamento do aplicativo de delivery
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          label="E-mail"
                          name="username"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiUser
                                  color={emailFocus ? '#2969D1' : undefined}
                                />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label="Senha"
                          name="password"
                          type={!showPassword ? 'password' : ''}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FiLock
                                  color={passwordFocus ? '#2969D1' : undefined}
                                />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  size="small"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <FiEyeOff /> : <FiEye />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={isSubmitting(form)}
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          Entrar
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.link}
                          align="right"
                          onClick={handleRecover}
                        >
                          Recuperar Senha
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Box>
      </Grid>
      <Grid xs item className={classes.bg} />
    </Grid>
  )
}

export default Login
