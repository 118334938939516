import Select, { SelectProps } from '@material-ui/core/Select'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  useTheme
} from '@material-ui/core'
import { Option } from 'types/generalTypes'

type CustomSelectProps = SelectProps & {
  name?: string
  defaultValue?: any
  disabled?: boolean
  error?: boolean
  helperText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  showEmpty?: boolean
  emptyLabel?: string
  items: Option[]
  label: string
}

const CustomSelect = ({
  defaultValue,
  items,
  label,
  name,
  ...other
}: CustomSelectProps) => {
  const theme = useTheme()
  return (
    <FormControl size="small" fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select {...other} label={label}>
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {other.error && (
        <FormHelperText style={{ color: theme.palette.error.main }}>
          {other.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

CustomSelect.defaultProps = {
  defaultValue: null,
  disabled: false,
  error: false,
  helperText: '',
  variant: 'outlined',
  showEmpty: false,
  emptyLabel: 'selecione'
}

export default CustomSelect
