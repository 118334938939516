export function formatPhone(value: string) {
  let r = value.replace(/\D/g, '')
  r = r.replace(/^0/, '')

  if (r.length > 11) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (r.length > 7) {
    r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3')
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else if (value.trim() !== '') {
    r = r.replace(/^(\d*)/, '($1')
  }
  return r
}
export function formatCPF(cpf: string) {
  let v = cpf.replace(/\D/g, '')
  v = v.replace(/^(\d{3})(\d)/, '$1.$2')
  v = v.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
  v = v.replace(/\.(\d{3})(\d)/, '.$1-$2')
  return v
}

export function formatCNPJ(cnpj: string) {
  let v = cnpj.replace(/\D/g, '')
  v = v.replace(/^(\d{2})(\d)/, '$1.$2')
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
  v = v.replace(/(\d{4})(\d)/, '$1-$2')
  return v
}

export function removeFormat(val: string) {
  const x = val.replace(/\D/g, '')
  return x
}
