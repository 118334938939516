import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableContainer } from 'components'
import Card from 'components/Card'
import Spacer from 'components/Spacer'
import Status from 'components/Status'
import { TableCellHead } from 'components/TableCellHead'
import { format } from 'date-fns'
import { ICompany } from '../../types/types'
import { useState } from 'react'
import ConfirmModal, { ConfirmModalProps } from 'components/ConfirmModal'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Button
} from '@material-ui/core'
import { realApi } from 'services/api'
import useSnackbar from '@elentari/core/hooks/useSnackbar'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import EmptyListFeedback from 'components/EmptyListFeedback'
import emptyError from 'assets/emptyError.svg'
import { useHistory } from 'react-router-dom'
interface IProps {
  loading: boolean
  list?: ICompany[]
  fetch: () => Promise<void>
  totalCount: number
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%'
  },
  tableRow: {
    alignItems: 'center'
  },
  cnpj: {
    minWidth: '180px'
  },
  validityAnvisa: {
    minWidth: '210px'
  },
  validityControled: {
    minWidth: '245px'
  },
  statusCell: {
    minWidth: '150px'
  },
  nameCell: {
    maxWidth: '150px'
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
}))

export const TableList = ({ list, loading, fetch, totalCount }: IProps) => {
  const classes = useStyles()
  const { push } = useHistory()
  const [modal, setModal] = useState<ConfirmModalProps | undefined>()
  const [requestLoading, setRequestLoading] = useState(false)
  const [, snackbarActions] = useSnackbar()

  async function onDelete(id: number) {
    setRequestLoading(true)
    const response: any = await realApi.deleteCNPJLinkFromUser(id)
    if (response?.ok) {
      setModal(undefined)
      fetch()
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'O CNPJ foi removido com sucesso!'
      })
    } else {
      setModal(undefined)
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: 'Houve um erro ao tentar remover CNPJ'
      })
    }
    setRequestLoading(false)
  }
  return (
    <>
      <Spacer y={4} />
      <Card className={classes.card}>
        <TableContainer loading={loading} totalCount={totalCount || 0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead label="CNPJ" sort="cnpj" />
                <TableCellHead label="Nome Fantasia" />
                <TableCellHead label="Anvisa / Validade" />
                <TableCellHead label="Controlado / Validade" />
                <TableCellHead label="Status" sort="status" />
                <TableCellHead label="" />
              </TableRow>
            </TableHead>
            <TableBody>
              {!!list?.length &&
                list?.map(({ id, company, status }) => (
                  <TableRow key={id} className={classes.card}>
                    <TableCell className={classes.cnpj}>
                      {company.cnpj}
                    </TableCell>
                    <TableCell className={classes.nameCell}>
                      {company.nomeFantasia}
                    </TableCell>
                    {company.validadeAnvisa ? (
                      <TableCell className={classes.validityAnvisa}>
                        <Grid container spacing={2}>
                          <Grid item xs="auto">
                            {company.anvisa === 'S' ? (
                              <CheckIcon
                                fontSize="small"
                                className={classes.success}
                              />
                            ) : (
                              <CloseIcon
                                fontSize="small"
                                className={classes.error}
                              />
                            )}
                          </Grid>
                          <Grid item xs>
                            <Box fontWeight="fontWeightBold" component="span">
                              {format(
                                new Date(company.validadeAnvisa),
                                'dd/MM/yyyy'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}

                    {company.validadeProdutoControlado ? (
                      <TableCell className={classes.validityControled}>
                        <Grid container spacing={2}>
                          <Grid item xs="auto">
                            {company.produtoControlado === 'S' ? (
                              <CheckIcon
                                fontSize="small"
                                className={classes.success}
                              />
                            ) : (
                              <CloseIcon
                                fontSize="small"
                                className={classes.error}
                              />
                            )}
                          </Grid>
                          <Grid item xs>
                            <Box fontWeight="fontWeightBold" component="span">
                              {format(
                                new Date(company.validadeProdutoControlado),
                                'dd/MM/yyyy'
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    <TableCell className={classes.statusCell}>
                      <Status status={status} />
                    </TableCell>
                    <TableCell>
                      <Grid container alignItems="center" spacing={2}>
                        {status === 'PENDING' && (
                          <Grid item xs="auto">
                            <Button
                              onClick={() => push(`/users-companies/${id}`)}
                              variant="outlined"
                            >
                              Analisar
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs="auto">
                          <IconButton
                            onClick={() => {
                              setModal({
                                open: true,
                                title: 'Excluir CNPJ?',
                                description:
                                  'Tem certeza que deseja excluir o CNPJ selecionado?',
                                src: '/remove-illustration.png',
                                handleConfirm: () => onDelete(id),
                                handleClose: () => setModal(undefined)
                              })
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!list ||
          (!list.length && !loading && (
            <>
              <EmptyListFeedback
                src={emptyError}
                title="LISTA VAZIA"
                description="Não encontramos nenhuma empresa vinculada!"
              />
              <Spacer y={4} />
            </>
          ))}
      </Card>
      {modal && <ConfirmModal {...modal} loading={requestLoading} />}
    </>
  )
}
