import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Card from 'components/Card'
import { ImportCnpjCsv } from 'components/importCnpjCsv'
import Snackbar from 'components/Snackbar'
import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { realApi } from 'services/api'
import { IGroup } from '../types/types'
import { IconButton, TextField, Typography, useTheme } from '@material-ui/core'
import NotificationList from './NotificationList'
import GroupsAutocomplete, {
  GroupsOptions
} from 'components/GroupsAutocomplete'
import EditIcon from '@material-ui/icons/EditOutlined'
import { useSnackbar } from '@elentari/core'
import { DrawerContext } from 'context/Drawer.context'
import GroupCompaniesList from '../list/GroupCompaniesList'
import { useGroupCompaniesList } from '../hooks/useGroupsCompanies'

const useStyles = makeStyles(theme => ({
  iconHoverShow: {
    position: 'absolute'
  },
  card: {
    overflow: 'initial'
  },
  buttonWrapper: {
    marginLeft: 'auto'
  },
  body: {
    width: '60vw'
  },
  listItem: {
    height: '100%'
  },
  container: {
    display: 'unset !important'
  }
}))

export interface IMessage {
  message: string
  error: boolean
  info?: boolean
}

interface IProps {
  fetch: (id: number) => Promise<void>
}

export const GroupDetails = ({ id, name, fetch }: IGroup & IProps) => {
  const { open } = useContext(DrawerContext)
  const [groupCompaniesState, groupCompaniesActions] = useGroupCompaniesList()
  const [requestLoading, setRequestLoading] = useState(false)
  const [message, setMessage] = useState<IMessage | null>(null)
  const [nameField, setNameField] = useState(name)
  const [editing, setEditing] = useState(false)
  const [, snackbarActions] = useSnackbar()
  const { push } = useHistory()
  const classes = useStyles()

  useEffect(() => {
    handleGetCompanies('')
  }, [])

  async function handleSubmit(companies: GroupsOptions[]) {
    setMessage({
      message: 'Adicionando empresa ao grupo. Aguarde!',
      info: true,
      error: false
    })

    try {
      const treatmentSelectedCompaniesArr = companies.map(({ value }) => value)
      const response: any = await realApi.insertGroupCompanies(
        id,
        treatmentSelectedCompaniesArr
      )
      if (!response.ok) {
        throw new Error(JSON.stringify(response.data))
      }
      if (response.data.occurrences.length) {
        snackbarActions.setSnackBar({
          message: `Alguns CNPJ's não foram adicionados ou por já estarem adicionados ou por não existirem!`,
          severity: 'warning'
        })
      }
      await fetch(Number(id))
      groupCompaniesActions.fetchPage()
      setMessage({ message: 'Empresa adicionada com sucesso', error: false })
      return response.ok
    } catch (err: any) {
      const converErro: any = JSON.parse(err.message)
      setMessage({ message: converErro.message, error: true })
    }

    return false
  }

  async function handleImportCnpj(cnpjs: string[]) {
    try {
      const response: any = await realApi.insertGroupCompanies(id, cnpjs)
      if (!response.ok) {
        throw new Error(JSON.stringify(response.data))
      }
      if (response.data.occurrences.length) {
        snackbarActions.setSnackBar({
          message: `Alguns CNPJ's não foram adicionados ou por já estarem adicionados ou por não existirem!`,
          severity: 'warning'
        })
      }
      await fetch(Number(id))
      groupCompaniesActions.fetchPage()
      setMessage({ message: 'Empresas adicionadas com sucesso', error: false })
      return 'ok'
    } catch (err: any) {
      const converErro: any = JSON.parse(err.message)
      setMessage({ message: converErro.message, error: true })
      return 'error'
    }
  }
  async function handleGetCompanies(value: string) {
    const response = await realApi.getCompanies(value, 100)
    if (response.ok && response.data) {
      const dataTreatment = response.data.map(item => ({
        label: item.nomeFantasia,
        value: item.cnpj
      }))
      return dataTreatment
    }
    snackbarActions.setSnackBar({
      message: 'Houve um erro ao carregar os dados, contate o suporte!',
      severity: 'warning'
    })
    return [{ label: '', value: '' }]
  }

  const updateName = async () => {
    setRequestLoading(true)
    const res = await realApi.updateGroup(id, { name: nameField })
    if (res.ok) {
      snackbarActions.setSnackBar({
        message: 'Nome atualizado com sucesso!',
        severity: 'success'
      })
      setEditing(false)
      fetch(Number(id))
    } else {
      snackbarActions.setSnackBar({
        message: 'Houve um erro ao atualizar nome, tente novamente mais tarde.',
        severity: 'warning'
      })
      setEditing(false)
    }
    setRequestLoading(false)
  }

  return (
    <Box marginLeft={1} marginBottom={2}>
      <Grid container>
        <Grid item xs={open ? 7 : false} className={classes.body}>
          <Grid container className={classes.container}>
            <Grid item xs={12}>
              <Card
                className={classes.card}
                backButton={e => push('/group')}
                title="Adicionar empresa ao grupo"
              >
                <Grid container spacing={2}>
                  <Grid item xs>
                    <GroupsAutocomplete
                      buttonTitle="Salvar"
                      onFormSubmit={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                title={
                  !editing ? (
                    `Grupo | ${name}`
                  ) : (
                    <>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs="auto">
                          <Typography variant="subtitle2">Grupo |</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <TextField
                            defaultValue={nameField}
                            onChange={e => setNameField(e.target.value)}
                            margin="dense"
                            size="small"
                            variant="outlined"
                            color="primary"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )
                }
                sideButton={
                  !editing ? (
                    <Tooltip title="Editar">
                      <IconButton onClick={() => setEditing(true)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setEditing(false)}
                          color="secondary"
                          variant="outlined"
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          color="primary"
                          onClick={updateName}
                          variant="contained"
                          disabled={requestLoading}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </Grid>
                  )
                }
              >
                <GroupCompaniesList
                  state={groupCompaniesState}
                  actions={groupCompaniesActions}
                  groupId={id}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={open ? 5 : true}>
          <NotificationList groupId={id} />
        </Grid>
      </Grid>

      <ImportCnpjCsv saveValues={handleImportCnpj} />
      {message && (
        <Snackbar
          severity={message.error ? 'error' : message.info ? 'info' : 'success'}
          onClose={() => setMessage(null)}
          message={message.message}
        />
      )}
    </Box>
  )
}
