import { useQueryParams } from '@elentari/core'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from 'components/Card'
import { Input } from 'components/Input'
import RFFSelect from 'components/RFFSelect'
import { SubmitValues } from 'hooks/types'
import { Form } from 'react-final-form'
import { BannersQueryFilter } from './types'

const BannersListFilter = () => {
  const { clear, push, initialValues } = useQueryParams<BannersQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.createdAt) {
      return push({
        ...values
      })
    }

    push({
      ...values,
      createdAt: undefined
    })
  }
  const format = (initialValues: SubmitValues) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues
      }
    }
    return initialValues
  }

  return (
    <Card>
      <Form
        initialValues={format(initialValues)}
        onSubmit={(values: any) => {
          handleSubmitFilter({ ...values, page: 1, size: 10 })
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    <Box fontWeight="fontWeightBold" component="span">
                      FILTRO
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Input name="title" label="Título" />
                </Grid>
                <Grid item xs={3}>
                  <RFFSelect
                    name="segment"
                    label="Segmento"
                    items={[
                      { label: 'Comida', value: 'FOOD' },
                      { label: 'Farmacêutico', value: 'FARMA' },
                      { label: 'Ambos', value: 'AMBOS' }
                    ]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <RFFSelect
                    name="type"
                    label="Tipo"
                    items={[
                      { label: 'Produtos', value: 'PRODUCTS' },
                      { label: 'Seções', value: 'SECTIONS' },
                      { label: 'Avisos', value: 'WARNING' }
                    ]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <RFFSelect
                    name="active"
                    label="Status"
                    items={[
                      { label: 'Ativo', value: 'true' },
                      { label: 'Inativo', value: 'false' }
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item xs="auto">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => clear()}
                      >
                        LIMPAR
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button variant="contained" color="primary" type="submit">
                        BUSCAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </Card>
  )
}

export default BannersListFilter
