import { MaskedField } from './Forms/MaskedField'

export const DateField = ({
  label,
  name,
  disabled
}: {
  label: string
  name: string
  disabled?: boolean
}) => (
  <MaskedField
    disabled={disabled}
    name={name}
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    label={label}
  />
)
