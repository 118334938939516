import Card from 'components/Card'
import Grid from '@material-ui/core/Grid'
import { SubmitValues } from 'hooks/types'
import { useQueryParams } from '@elentari/core'
import { UserQueryFilter } from './types'
import {
  formatCNPJ,
  formatCPF,
  removeFormat,
  formatPhone
} from 'utils/formatString'
import { useState } from 'react'
import { Input } from 'components/Input'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Form } from 'react-final-form'
import { Button } from '@material-ui/core'

export const FilterPanel = () => {
  const { clear, push, initialValues } = useQueryParams<UserQueryFilter>()
  const [cpf, setCpf] = useState<string>('')
  const [cnpj, setCnpj] = useState<string>('')
  const [phone, setPhone] = useState<string>('')

  const handleSubmitFilter = (values: SubmitValues) => {
    if (values.createdAt) {
      return push({
        ...values
      })
    }
    push({
      ...values,
      name: values.name,
      email: values.email,
      createdAt: undefined
    })
  }

  const clearAll = () => {
    clear()
    setCpf('')
    setPhone('')
    setCnpj('')
  }

  const format = (initialValues: SubmitValues) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues
      }
    }
    return initialValues
  }

  return (
    <Card>
      <Form
        initialValues={format(initialValues)}
        onSubmit={values => {
          const valUpdated = {
            ...values,
            cpf: removeFormat(cpf),
            phone: removeFormat(phone),
            cnpj: removeFormat(cnpj),
            page: 1,
            size: 10
          }
          handleSubmitFilter(valUpdated)
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    <Box fontWeight="fontWeightBold" component="span">
                      FILTRO
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Input name="name" label="Nome" />
                </Grid>
                <Grid item xs>
                  <Input name="email" label="E-mail" />
                </Grid>
                <Grid item xs>
                  <Input
                    value={cpf}
                    name="cpf"
                    label="CPF"
                    onChange={e =>
                      e.target.value.length <= 14
                        ? setCpf(formatCPF(e.target.value))
                        : null
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Input
                    value={cnpj}
                    name="cnpj"
                    label="CNPJ"
                    onChange={e =>
                      e.target.value.length <= 18
                        ? setCnpj(formatCNPJ(e.target.value))
                        : null
                    }
                  />
                </Grid>
                <Grid item xs>
                  <Input
                    name="phone"
                    label="Celular"
                    value={phone}
                    onChange={e => setPhone(formatPhone(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs="auto">
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={clearAll}
                      >
                        LIMPAR
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        color="primary"
                        disableElevation
                        variant="contained"
                        type="submit"
                      >
                        BUSCAR
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </Card>
  )
}
