import Pagination from '@material-ui/lab/Pagination'
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
interface CustomPaginationProps {
  count: number
  hideLimit?: boolean
  onlyArrows?: boolean
  disableFoward?: boolean
}

const CustomPagination = ({
  count = 0,
  hideLimit,
  onlyArrows,
  disableFoward
}: CustomPaginationProps) => {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  const page = Number(params.get('page')) || 1
  const [limit, setLimit] = useState(10)
  const totalPages = Math.ceil(count / limit)

  const handleChangePage = (event: any, page: number) => {
    const { location } = history
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('page', String(page))
    searchParams.set('size', String(limit))
    history.push(`${location.pathname}?${searchParams.toString()}`)
  }

  const handleChangeLimit = () => {
    const { location } = history
    const searchParams = new URLSearchParams(location.search)
    if (totalPages < 2) {
      searchParams.set('page', String(1))
    }
    searchParams.set('size', String(limit))
    history.push(`${location.pathname}?${searchParams.toString()}`)
  }

  useEffect(() => {
    handleChangeLimit()
  }, [limit])

  return (
    <Grid container justify={hideLimit ? 'flex-end' : 'space-between'}>
      {!hideLimit && (
        <Grid item xs="auto">
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs="auto">
              <Typography color="textPrimary">Mostrando</Typography>
            </Grid>
            <Grid item xs="auto">
              <FormControl>
                <Select
                  value={limit}
                  onChange={(e: any) => setLimit(e.target.value)}
                  displayEmpty
                  color="primary"
                  label="Linhas"
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs="auto">
              <Typography color="textPrimary">linhas por página</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {totalPages > 1 && !onlyArrows && (
        <Grid item xs="auto">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
      {totalPages > 1 && onlyArrows && (
        <Grid item xs="auto">
          <Grid container spacing={2}>
            <Grid item xs="auto">
              <IconButton
                disabled={page === 1}
                onClick={() => handleChangePage(null, page - 1)}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                disabled={disableFoward}
                onClick={() => handleChangePage(null, page + 1)}
              >
                <ChevronRightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CustomPagination
