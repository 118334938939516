import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useContext
} from 'react'
import { State, User, AuthContext as AuthContextType } from './types'
import api from '../../services/api'
import { Ability } from '@casl/ability'

export const AuthContext = createContext<AuthContextType>({
  initialized: false
})
export const { Consumer } = AuthContext

export const useAbility = () => {
  const context = useContext(AuthContext)
  return context.initialized ? context.ability : undefined
}

export const useLoggedUser = () => {
  const context = useContext(AuthContext)
  return context.initialized
    ? context.state.loggedin
      ? context.state.user
      : undefined
    : undefined
}

type Props = {
  children: ReactNode
}

const initialState: State = {
  loggedin: false
}

export default function AuthProvider({ children }: Props) {
  const [state, setState] = useState(initialState)
  const [ability, setAbility] = useState<Ability>()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const storedUser = localStorage.user
    if (storedUser) {
      const user = JSON.parse(storedUser) as User
      api.setToken(user.token)
      setState({
        user,
        loggedin: true
      })
      api.setToken(user.token)
    }
    setInitialized(true)
  }, [localStorage.user])

  const handleUserLogin = (user: User) => {
    localStorage.user = JSON.stringify(user)
    api.setToken(user.token)
    setState({
      user,
      loggedin: true
    })
  }

  const logout = async () => {
    delete localStorage.user
    api.setToken('')
    setAbility(undefined)
    setState({
      loggedin: false
    })
  }

  return (
    <AuthContext.Provider
      value={{
        initialized,
        state,
        ability,
        logout,
        handleUserLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
