import { Grid } from '@material-ui/core'
import { Errors } from 'components/Errors'
import React from 'react'
import { useGroupCompaniesList } from '../hooks/useGroupsCompanies'
import emptyError from 'assets/emptyError.svg'
import EmptyListFeedback from 'components/EmptyListFeedback'
import GroupCompanieCard from 'components/GroupCompanieCard'
import Pagination from 'components/Pagination'
import { Skeleton } from '@material-ui/lab'
import { GroupCompanies } from '../types/types'
import { ListState } from '@elentari/core/types'

type GroupCompaniesListProps = {
  groupId: string
  state: ListState<GroupCompanies>
  actions: {
    fetchPage: () => void
  }
}

type ListProps = {
  list: GroupCompanies[]
  totalCount: number
  fetch: () => void
  loading?: boolean
} & Omit<GroupCompaniesListProps, 'state' | 'actions'>

const List = ({ list, totalCount, fetch, groupId, loading }: ListProps) => {
  return (
    <Grid container spacing={2}>
      {!!list.length && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {list.map(group => (
                  <Grid item xs={12} sm={6} xl={4} key={group.id}>
                    <GroupCompanieCard
                      groupCompanie={group}
                      groupId={groupId}
                      fetch={fetch}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {list.length === 0 && !loading && (
        <Grid item xs={12}>
          <EmptyListFeedback
            src={emptyError}
            title="LISTA VAZIA"
            description="Ainda não temos grupos cadastrados, clique no botão de adicionar e adicione um grupo!"
          />
        </Grid>
      )}
      {loading &&
        Array.from(Array(10).keys()).map(i => (
          <Grid item xs={12} sm={6} xl={4} key={`skeleton-${i}`}>
            <Skeleton height={75} variant="rect" width="100%" />
          </Grid>
        ))}
      <Grid item xs={12}>
        <Pagination hideLimit count={totalCount} />
      </Grid>
    </Grid>
  )
}

const GroupCompaniesList = ({
  groupId,
  state,
  actions
}: GroupCompaniesListProps) => {
  switch (state.tag) {
    case 'with-data':
      return (
        <List
          list={state.list}
          totalCount={state.totalCount}
          fetch={actions.fetchPage}
          groupId={groupId}
        />
      )
    case 'empty':
      return (
        <List
          list={[]}
          totalCount={0}
          fetch={actions.fetchPage}
          loading={state.loading}
          groupId={groupId}
        />
      )
    case 'error':
      return <Errors stateError={state} />
  }
}

export default GroupCompaniesList
