import { createContext, ReactNode, useState } from 'react'
interface IDrawer {
  changeDrawerVisualization?: () => void
  open: boolean
  setOpen: (open: boolean) => void
}
interface IProps {
  children: ReactNode
}
export const DrawerContext = createContext<IDrawer>({} as IDrawer)

export const DrawerProvider = ({ children }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  function changeDrawerVisualization() {
    setOpen(!open)
  }
  return (
    <DrawerContext.Provider
      value={{ open, setOpen, changeDrawerVisualization }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
