import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  }
}))

type Props = {
  path: string
}

export const AddRecord = ({ path }: Props) => {
  const classes = useStyles()

  return (
    <Tooltip title="Adicionar registro" aria-label="Adicionar registro">
      <Fab
        component={Link}
        to={`${path}/new`}
        color="primary"
        aria-label="Add"
        className={classes.fab}
        onClick={() => {}}
      >
        <AddIcon />
      </Fab>
    </Tooltip>
  )
}
