import DateFnsUtils from '@date-io/date-fns'
import useSnackbar from '@elentari/core/hooks/useSnackbar'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dotenv from 'dotenv'
import { ResetPassword } from 'modules/login/ResetPassword'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Snackbar from './components/Snackbar'
import HomeNavigation from './modules/home/HomeNavigation'
import { AuthProvider, Login, PrivateRoute, AuthContext } from './modules/login'
import { RecoverPassword } from './modules/login/RecoverPassword'
import { withScollBar } from './style/App.styles'
import { DrawerProvider } from 'context/Drawer.context'
import './style/font.css'
import theme from './style/Theme'
import AuthenticateGuardian from 'components/AuthenticateGuardian'

dotenv.config()

const App = () => {
  const [snackbarState, snackbarActions] = useSnackbar()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DrawerProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AuthProvider>
            <AuthenticateGuardian />
            <Router>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/recover-pass" component={RecoverPassword} />
                <Route path="/resetar-senha" component={ResetPassword} />
                <PrivateRoute
                  loginPath="/login"
                  path="/"
                  render={() => <HomeNavigation />}
                />
              </Switch>
            </Router>
            <Snackbar
              message={snackbarState.message}
              severity={snackbarState.severity}
              onClose={() => snackbarActions.setMessage('')}
            />
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </DrawerProvider>
    </ThemeProvider>
  )
}

export default withScollBar(App)
