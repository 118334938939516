import { Grid, Typography, Box } from '@material-ui/core'

type EmptyListFeedbackProps = {
  src: string
  title: string
  description: string
}

const EmptyListFeedback = ({
  src,
  title,
  description
}: EmptyListFeedbackProps) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs="auto">
        <img src={src} alt={title} width="300px" />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary" align="center">
              <Box fontWeight="fontWeightBold" component="span">
                {title}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" color="textSecondary">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EmptyListFeedback
