import blueGrey from '@material-ui/core/colors/blueGrey'
import { styled } from '@material-ui/styles'
import { ListItemIcon, ListItemText, ListItem, Theme } from '@material-ui/core'
interface IProps {
  theme: Theme
  active?: boolean
  hover?: boolean
}
export const List = styled(ListItem)(({ theme, active }: IProps) => ({
  marginTop: theme.spacing(1),
  height: theme.spacing(7.5),
  alignItems: 'center',
  padding: theme.spacing(2.4),
  background: active ? '#00000040' : '',
  cursor: active ? 'default' : 'pointer',
  '&:hover': {
    background: active ? '#00000040' : ''
  }
}))
export const ItemIcon = styled(ListItemIcon)(({ theme, active }: IProps) => ({
  color: active ? '#fff' : '#ababab',
  fontWeight: 'normal'
}))

export const ItemText = styled(ListItemText)(({ theme, active }: IProps) => ({
  color: active ? '#fff' : '#ababab',
  fontWeight: 'initial'
}))
