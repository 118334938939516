import { useSnackbar } from '@elentari/core'
import { Errors } from 'components/Errors'
import { useEffect } from 'react'
import { useUsersCompaniesList } from '../../hooks/useFetchUsersCompanies'
import { TableList } from './Table'

const usersCompanies = () => {
  const [state, actions] = useUsersCompaniesList()
  const [, snackbarActions] = useSnackbar()

  async function fetchCustomers() {
    actions.fetchPage()
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  switch (state.tag) {
    case 'empty':
      return (
        <TableList
          list={[]}
          totalCount={0}
          loading={state.loading}
          fetch={fetchCustomers}
        />
      )
    case 'with-data':
      return (
        <TableList
          list={state.list}
          totalCount={state.totalCount}
          fetch={fetchCustomers}
          loading={state.loading}
        />
      )
    case 'error':
      return <Errors stateError={state} />
  }
}

export default usersCompanies
