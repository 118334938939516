import { makeGlobal } from '@elentari/core'
import qs from 'qs'
import { useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import {
  ListState,
  ListActions,
  StoreActions,
  Fetch,
  FetchSubRepository,
  UseList,
  Page
} from './types'
export function makeUseList<T>(fetch: Fetch<Page<T>>) {
  const initialState: ListState<T> = {
    tag: 'empty',
    loading: false
  }

  const actions: StoreActions<ListState<T>, ListActions> = {
    fetchPage: store => async params => {
      if (store.state.tag === 'error') {
        store.setState({ tag: 'empty', loading: true })
      } else {
        store.setState({ ...store.state, loading: true })
      }
      const response = await fetch(params)
      if (response.ok) {
        store.setState({
          tag: 'with-data',
          list: response.data?.data ?? [],
          totalCount: response.data?.total ?? 0,
          loading: false,
          page: Number(params?.page ?? 1),
          hasMore: true
        })
      } else {
        store.setState({
          tag: 'error',
          error: String(response.problem),
          status: response.status,
          data: response.data
        })
      }
    },
    reset: store => () => {
      store.setState(initialState)
    }
  }

  const useListState = makeGlobal<ListState<T>, ListActions>(
    initialState,
    actions
  )

  const useList = (): [ListState<T>, { fetchPage: () => void }] => {
    const [state, actions] = useListState()
    const location = useLocation()

    const fetchPage = () => {
      actions.fetchPage(qs.parse(location.search.substring(1)))
    }

    useEffect(() => {
      fetchPage()
      return actions.reset
    }, [location.search])

    return [state, { fetchPage }]
  }
  return useList
}

type ListActionsSubRepository = {
  fetchPage: (parentId: any, param: qs.ParsedQs) => Promise<void>
}

export function makeUseListSubRepository<T>(
  fetch: FetchSubRepository<Page<T>, any>
) {
  const initialState: ListState<T> = {
    tag: 'empty',
    loading: false
  }

  const actions: StoreActions<ListState<T>, ListActionsSubRepository> = {
    fetchPage: store => async (parentId, params) => {
      if (store.state.tag === 'error') {
        store.setState({ tag: 'empty', loading: true })
      } else {
        store.setState({ ...store.state, loading: true })
      }
      const response = await fetch(parentId, params)
      if (response.ok) {
        store.setState({
          tag: 'with-data',
          list: response.data?.data ?? [],
          totalCount: response.data?.total ?? 0,
          loading: false,
          page: Number(params?.page ?? 1),
          hasMore: true
        })
      } else {
        store.setState({
          tag: 'error',
          error: String(response.problem),
          status: response.status,
          data: response.data
        })
      }
    }
  }

  const useGlobal = makeGlobal<ListState<T>, ListActionsSubRepository>(
    initialState,
    actions
  )

  const useList = (): UseList<T> => {
    const [state, actions] = useGlobal()
    const location = useLocation()
    const match = useRouteMatch<{ id: string }>()

    const fetchPage = () => {
      actions.fetchPage(match.params.id, qs.parse(location.search.substring(1)))
    }

    useEffect(() => {
      fetchPage()
    }, [location.search])

    return [state, { fetchPage }]
  }
  return useList
}
