import useAuthenticationInterceptor from 'hooks/useAuthenticationInterceptor'
import { useContext } from 'react'
import { AuthContext } from '../modules/login'

const AuthenticateGuardian = () => {
  const logoutUser = (authContext: any) => authContext.logout()

  const authContext = useContext(AuthContext)

  useAuthenticationInterceptor({
    onLogout: () => {
      logoutUser(authContext)
    }
  })
  return <></>
}

export default AuthenticateGuardian
