import { createMuiTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'
const theme = createMuiTheme()
export default createMuiTheme(
  {
    typography: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
    },
    palette: {
      primary: {
        200: '#F6BB3B',
        500: '#2969D1',
        main: '#2969D1'
      },
      white: '#ffffff',
      text: {
        primary: '#3A3A3A',
        secondary: '#676767'
      },
      secondary: {
        main: '#676767'
      },
      action: {
        focus: '#dAdAeA'
      },
      background: { default: '#ffffff' }
    },
    overrides: {
      MuiCard: {
        root: {
          borderRadius: theme.spacing(1)
        }
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0
        }
      },
      MuiCardHeader: {
        title: {
          fontSize: '14px',
          fontWeight: 'bold'
        },
        subheader: {
          fontSize: '14px',
          color: theme.palette.text.secondary
        }
      },
      MuiTable: {
        root: {
          selected: {
            backgorund: '#000'
          }
        }
      },
      MuiTableHead: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      },
      MuiListItemText: {
        primary: {
          fontSize: '14px'
        }
      },
      MuiTableCell: {
        body: {
          borderBottom: 'none'
        }
      }
    }
  },
  ptBR
)
