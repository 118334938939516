import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip
} from '@material-ui/core'
import { GroupCompanies } from 'modules/group/types/types'
import { useState } from 'react'
import GroupValue from './GroupValue'
import { formatCNPJ } from 'utils/formatString'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ConfirmModal, { ConfirmModalProps } from './ConfirmModal'
import { IMessage } from 'modules/group/details'
import { realApi } from 'services/api'
import Snackbar from './Snackbar'

type GroupCompaniesCardProps = {
  groupCompanie: GroupCompanies
  groupId: string
  fetch: () => void
}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%'
  }
})

const GroupCompanieCard = ({
  groupCompanie,
  groupId,
  fetch
}: GroupCompaniesCardProps) => {
  const classes = useStyles()

  const [modal, setModal] = useState<ConfirmModalProps>()
  const [message, setMessage] = useState<IMessage | null>(null)
  const [requestLoading, setRequestLoading] = useState(false)

  async function handleDeleteCompanie(idCompanieGroup: number) {
    setRequestLoading(true)
    const res = await realApi.deleteCompanieFromGroup(idCompanieGroup)
    if (res.ok) {
      fetch()
      setMessage({ message: 'Empresa removida com sucesso', error: false })
      setModal(undefined)
    } else {
      setMessage({ message: 'Houve um erro ao remover empresa', error: true })
    }
    setRequestLoading(false)
  }

  return (
    <>
      <Paper className={classes.fullHeight}>
        <Box p={2} className={classes.fullHeight}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            alignContent="center"
            className={classes.fullHeight}
          >
            <Grid item xs>
              <GroupValue
                title={groupCompanie.company.nomeFantasia}
                value={formatCNPJ(String(groupCompanie.company.cnpj))}
                valueSize={19}
                disableTooltip
              />
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Remover empresa do grupo">
                <IconButton
                  onClick={() =>
                    setModal({
                      open: true,
                      title: 'Remover empresa do grupo?',
                      description:
                        'Tem certeza que deseja remover a empresa do  grupo ?',
                      src: '/remove-illustration.png',
                      handleConfirm: () =>
                        handleDeleteCompanie(groupCompanie.id),
                      handleClose: () => setModal(undefined)
                    })
                  }
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {modal && <ConfirmModal {...modal} loading={requestLoading} />}
      {message && (
        <Snackbar
          severity={message.error ? 'error' : message.info ? 'info' : 'success'}
          onClose={() => setMessage(null)}
          message={message.message}
        />
      )}
    </>
  )
}

export default GroupCompanieCard
