import { useCallback, useEffect } from 'react'

import api, { apiSauceInstance } from '../services/api'

const TOKEN_VALIDATION_PERIOD = 50000

type AuthenticationInterceptorProps = {
  onLogout?: () => void
}

const useAuthenticationInterceptor = ({
  onLogout
}: AuthenticationInterceptorProps) => {
  const onErrorInterceptor = useCallback(
    (error: any) => {
      const status = error.response ? error.response.status : null
      if ([401, 403].includes(status)) {
        if (onLogout) {
          onLogout()
        }
      }
      return error
    },
    [onLogout]
  )

  useEffect(() => {
    const interceptorId =
      apiSauceInstance.axiosInstance.interceptors.response.use(
        response => response,
        onErrorInterceptor
      )
    return () =>
      apiSauceInstance.axiosInstance.interceptors.response.eject(interceptorId)
  }, [onErrorInterceptor])

  useEffect(() => {
    const timeout = setTimeout(api.ping, TOKEN_VALIDATION_PERIOD)
    return () => clearTimeout(timeout)
  }, [])

  return null
}

export default useAuthenticationInterceptor
