import { useSnackbar } from '@elentari/core'
import { Errors } from 'components/Errors'
import { useEffect, useState } from 'react'
import { useUsersList } from '../hooks/useUsersCompanies'
import { UsersTable } from './Table'

const Users = () => {
  const [state, actions] = useUsersList()

  useEffect(() => {
    fetchUserCompanies()
  }, [])

  async function fetchUserCompanies() {
    actions.fetchPage()
  }

  switch (state.tag) {
    case 'empty':
      return <UsersTable list={[]} totalCount={0} loading={true} />
    case 'with-data':
      return (
        <UsersTable
          list={state.list}
          totalCount={state.totalCount}
          loading={state.loading}
          onStatusUpdated={fetchUserCompanies}
        />
      )
    case 'error':
      return <Errors stateError={state} />
  }
}

export default Users
