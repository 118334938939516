import { Field, FieldRenderProps } from 'react-final-form'
import Select, { SelectProps } from '@material-ui/core/Select'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { Option } from 'types/generalTypes'

type RFFSelectProps = SelectProps & {
  name: string
  defaultValue?: any
  disabled?: boolean
  error?: boolean
  helperText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  showEmpty?: boolean
  emptyLabel?: string
  items: Option[]
  label: string
}

const RFFSelect = ({
  defaultValue,
  items,
  label,
  name,
  ...other
}: RFFSelectProps) => (
  <Field defaultValue={defaultValue} name={name}>
    {({
      input,
      meta,
      ...props
    }: FieldRenderProps<string, HTMLInputElement | HTMLTextAreaElement>) => (
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel>{label}</InputLabel>
        <Select
          {...input}
          {...props}
          {...other}
          error={meta.error && meta.touched}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          label={label}
        >
          {items.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </Field>
)

RFFSelect.defaultProps = {
  defaultValue: null,
  disabled: false,
  error: false,
  helperText: '',
  variant: 'outlined',
  showEmpty: false,
  emptyLabel: 'selecione'
}

export default RFFSelect
