import Drawer from '@material-ui/core/Drawer'
import logo from 'assets/logo_white.svg'
import { DrawerBody, DrawerHeader } from './style'
import MenuItems from '../MenuItems'
import { useContext, useState } from 'react'
import { DrawerContext } from 'context/Drawer.context'

const SideBarDrawer = () => {
  const { open } = useContext(DrawerContext)
  const [mouseOut, setMouseOut] = useState(false)
  const drawerWidth = '240px'
  const drawerClosedWidth = '60px'

  return (
    <Drawer
      variant="permanent"
      onMouseOut={() => setMouseOut(true)}
      onMouseEnter={() => setMouseOut(false)}
      style={{ width: open ? drawerWidth : drawerClosedWidth }}
    >
      <DrawerBody open={open}>
        <DrawerHeader open={open}>
          <img src={logo} alt="logo" width="30" height="30" />
        </DrawerHeader>
        <MenuItems mouseOutDrawer={mouseOut} />
      </DrawerBody>
    </Drawer>
  )
}
export default SideBarDrawer
