import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableContainerMD from '@material-ui/core/TableContainer'
import { CSSProperties } from '@material-ui/styles'
import { ReactNode } from 'react'
import Pagination from './Pagination'
import Spacer from './Spacer'
import When from './When'

export const TableContainer = ({
  children,
  loading,
  totalCount,
  style,
  hideLimit
}: {
  children: ReactNode
  totalCount: number
  loading: boolean
  style?: CSSProperties
  hideLimit?: boolean
}) => {
  return (
    <TableContainerMD style={style}>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {children}

      <Spacer y={2} />
      <Grid container>
        <Grid item xs={12}>
          <Pagination hideLimit count={totalCount} />
        </Grid>
      </Grid>
      <Spacer y={2} />
    </TableContainerMD>
  )
}
