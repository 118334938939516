import { Box, Typography, Grid } from '@material-ui/core'
interface IProps {
  title: string
  value?: string
  disableTooltip?: boolean
  valueSize?: number
}
const GroupValue = ({ title, value, disableTooltip, valueSize }: IProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          color="textSecondary"
          variant="caption"
          style={{ textTransform: 'uppercase' }}
        >
          <Box fontWeight="fontWeightBold">{title}</Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {value && (
          <Typography variant="h6" style={{ fontSize: valueSize }}>
            <Box fontWeight="fontWeightBold" component="span">
              {value}
            </Box>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default GroupValue
