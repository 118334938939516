import Box from '@material-ui/core/Box/Box'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'

export const SkeletonCard = () => {
  return (
    <Box marginLeft={3} marginRight={1}>
      <Skeleton variant="text" width="100px" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={100} />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100px" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="60px" />
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="100%" height="40px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={100} />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100px" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="60px" />
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="100%" height="40px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={100} />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100px" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="60px" />
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="100%" height="40px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={100} />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100px" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="60px" />
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="100%" height="40px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Skeleton variant="rect" width="100%" height={100} />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="100px" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="text" width="60px" />
                </Grid>
                <Grid container xs={12} justify="flex-end">
                  <Grid item xs={3}>
                    <Skeleton variant="rect" width="100%" height="40px" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
