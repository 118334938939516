import Skeleton from '@material-ui/lab/Skeleton'
import Spacer from 'components/Spacer'
import Card from 'components/Card'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflow: 'initial'
  },
  leftAuto: {
    marginLeft: 'auto'
  }
}))

const BannersSkeleton = () => {
  const { push } = useHistory()
  const classes = useStyles()
  return (
    <Box marginLeft={1}>
      <Grid item>
        <Card
          className={classes.card}
          backButton={() => push('/banners')}
          title="DETALHES DO BANNER"
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={5}>
                  <Skeleton variant="rect" width="100%" height="170px" />
                </Grid>
                <Grid item xs>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={11}>
                      <Skeleton variant="rect" width="100%" height="30px" />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid item xs={12}>
                        <Skeleton variant="rect" width="100%" height="30px" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  )
}

export default BannersSkeleton
