/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react'
import {
  IBanner,
  IBannerSegmentTranslate,
  IBannerTypeTranslate
} from '../types/types'
import Card from 'components/Card'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import ImageSelector from './../../../components/ImageSelector'
import { realApi } from 'services/api'
import { useSnackbar } from '@elentari/core'
import * as yup from 'yup'
import { ValidationErrors } from 'final-form'
import { useForm, useField } from 'react-final-form-hooks'
import GroupValue from 'components/GroupValue'
import Status from 'components/Status'
import EditIcon from '@material-ui/icons/EditOutlined'
import ProductsAutocomplete, {
  BannerAutoCompleteOptions
} from 'components/ProductsAutocomplete'
import SectionsAutocomplete from 'components/SectionsAutocomplete'
import { endOfDay, startOfDay } from 'date-fns'
import ItemsList from './ItemsList'
import getDate from 'utils/getDate'

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'O campo precisa estar preenchido'
  },
  string: {
    min: 'O mínimo de caracteres é ${min}',
    max: 'O máximo de caracteres é ${max}'
  }
})

const currentDay = startOfDay(new Date())

const schema = yup.object().shape({
  titleInput: yup.string().max(100).required(),
  finalDateInput: yup
    .date()
    .min(
      currentDay,
      `A data final não pode ser anterior a ${endOfDay(
        new Date()
      ).toLocaleDateString()}`
    )
    .nullable(),
  initialDateDefault: yup.date(),
  initialDateInput: yup
    .date()
    .when(
      'initialDateDefault',
      (initialDateDefault: Date, schema: any) =>
        initialDateDefault &&
        schema.min(
          initialDateDefault,
          'Data inicial não pode ser maior que a data inicial anterior'
        )
    )
    .when(
      'finalDateInput',
      (finalDate: Date, schema: any) =>
        finalDate &&
        schema.max(
          finalDate,
          `A data inicial não pode ser maior que a data final`
        )
    )
    .required(),
  sequenceInput: yup.number().max(9999).min(0.01).required()
})

interface IProps {
  fetch: (id: number) => void
}

interface BannersEditData {
  titleInput?: string
  initialDateInput?: string
  finalDateInput?: string
  sequenceInput?: string
  initialDateDefault?: string
}

const BannersDetails = ({
  id,
  type,
  segment,
  title,
  bannerUrl,
  active,
  fetch,
  initialDate,
  finalDate,
  items,
  sequence
}: IBanner & IProps) => {
  const { push } = useHistory()
  const [, snackbarActions] = useSnackbar()
  const [editing, setEditing] = useState(false)
  const [addingPosition, setAddingPosition] = useState<{
    current: number
    total: number
  } | null>()
  const handleImageEdit = async (file: Blob) => {
    if (file) {
      const form = new FormData()
      form.append('banner', file)
      const response: any = await realApi.editBanner(id, form)
      if (response.ok) {
        snackbarActions.setSnackBar({
          severity: 'success',
          message: 'A foto do banner foi alterada com sucesso!'
        })
        fetch(id)
      } else {
        snackbarActions.setSnackBar({
          severity: 'warning',
          message: response.data.errors[0].message
        })
      }
    }
  }

  const onSubmit = async (values: BannersEditData & { active?: boolean }) => {
    const response: any = await realApi.editBanner(id, {
      title: values.titleInput,
      initialDate:
        values.initialDateInput &&
        new Date(values.initialDateInput).toISOString(),
      finalDate: values.finalDateInput,
      sequence: values.sequenceInput,
      active: values.active
    })
    if (response.ok) {
      snackbarActions.setSnackBar({
        severity: 'success',
        message: 'Banner atualizado com sucesso!'
      })
      fetch(id)
      setEditing(false)
    } else {
      snackbarActions.setSnackBar({
        severity: 'warning',
        message: response.data.errors[0].message
      })
    }
  }

  const validate = (values: BannersEditData) => {
    try {
      schema.validateSync(values, { abortEarly: false })
    } catch (errors: any) {
      const formErrors: any = {}
      errors.inner.forEach((erro: ValidationErrors) => {
        formErrors[erro?.path] = erro?.message
      })
      return formErrors
    }
  }

  const { form, handleSubmit, values, pristine, submitting, initialValues } =
    useForm({
      onSubmit,
      initialValues: {
        titleInput: title,
        initialDateDefault: getDate.forField(initialDate),
        initialDateInput: getDate.forField(initialDate),
        finalDateInput: getDate.forField(finalDate),
        sequenceInput: sequence
      },
      validate
    })

  const titleInput = useField('titleInput', form)
  const initialDateDefault = useField('initialDateDefault', form)
  const initialDateInput = useField('initialDateInput', form)
  const finalDateInput = useField('finalDateInput', form)
  const sequenceInput = useField('sequenceInput', form)

  const addItems = async (items: BannerAutoCompleteOptions[]) => {
    const totalItems = items.length
    for await (const [index, item] of items.entries()) {
      setAddingPosition({ total: totalItems, current: index })
      const response: any =
        type === 'PRODUCTS'
          ? await realApi.addProductToBanner(id, Number(item.value))
          : await realApi.addSectionToBanner(id, Number(item.value))
      if (response.ok) {
        snackbarActions.setSnackBar({
          severity: 'success',
          message: `${
            type === 'PRODUCTS' ? 'Produto' : 'Seção'
          } adicionado com sucesso!`
        })
      } else {
        snackbarActions.setSnackBar({
          severity: 'warning',
          message: response.data.message
        })
      }
    }
    setAddingPosition(null)
    fetch(id)
    return true
  }

  return (
    <Card backButton={() => push('/banners')} title="DETALHES DO BANNER">
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={5}>
          <ImageSelector
            width="100%"
            height="180px"
            value={bannerUrl}
            onChange={handleImageEdit}
          />
        </Grid>
        <Grid item xs>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    {editing ? (
                      <TextField
                        label="Título"
                        size="small"
                        variant="outlined"
                        fullWidth
                        {...titleInput.input}
                        error={
                          titleInput.meta.touched && titleInput.meta.invalid
                        }
                        helperText={
                          titleInput.meta.touched &&
                          titleInput.meta.invalid &&
                          titleInput.meta.error
                        }
                      />
                    ) : (
                      <Grid container alignItems="center">
                        <Grid item xs="auto">
                          <GroupValue title="Título" value={title} />
                        </Grid>
                        <Grid item xs="auto">
                          <IconButton onClick={() => setEditing(true)}>
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs="auto">
                    {!editing ? (
                      <Grid item xs={4}>
                        <Button
                          onClick={() =>
                            onSubmit({
                              active: !active
                            })
                          }
                          color="primary"
                          variant="contained"
                          disabled={!bannerUrl}
                        >
                          {active ? 'Inativar' : 'Ativar'}
                        </Button>
                      </Grid>
                    ) : (
                      <Grid container spacing={2} justify="flex-end">
                        <Grid item xs="auto">
                          <Button
                            onClick={() => setEditing(false)}
                            fullWidth
                            variant="outlined"
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Grid item xs="auto">
                          <Button
                            disabled={submitting || pristine}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!editing ? (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <GroupValue
                        value={getDate.toShow(initialDate)}
                        title="Data inicial"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <GroupValue
                        value={getDate.toShow(finalDate)}
                        title="Data final"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <GroupValue value={sequence} title="Ordem" />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        label="Data inicial"
                        size="small"
                        type="date"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        {...initialDateInput.input}
                        error={
                          initialDateInput.meta.touched &&
                          initialDateInput.meta.invalid
                        }
                        helperText={
                          initialDateInput.meta.touched &&
                          initialDateInput.meta.invalid &&
                          initialDateInput.meta.error
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Data final"
                        size="small"
                        variant="outlined"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        {...finalDateInput.input}
                        error={
                          finalDateInput.meta.touched &&
                          finalDateInput.meta.invalid
                        }
                        helperText={
                          finalDateInput.meta.touched &&
                          finalDateInput.meta.invalid &&
                          finalDateInput.meta.error
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Ordem"
                        type="number"
                        size="small"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          step: 0.01
                        }}
                        {...sequenceInput.input}
                        error={
                          sequenceInput.meta.touched &&
                          sequenceInput.meta.invalid
                        }
                        helperText={
                          sequenceInput.meta.touched &&
                          sequenceInput.meta.invalid &&
                          sequenceInput.meta.error
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ textTransform: 'uppercase' }}
                    >
                      <Box fontWeight="fontWeightBold">Status</Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Status status={active ? 'ACTIVE' : 'DISABLED'} bold />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <GroupValue
                  value={IBannerSegmentTranslate[segment]}
                  title="Segmento"
                />
              </Grid>
              <Grid item xs={4}>
                <GroupValue value={IBannerTypeTranslate[type]} title="Tipo" />
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          {type === 'PRODUCTS' && (
            <ProductsAutocomplete
              onFormSubmit={addItems}
              segment={segment}
              buttonTitle="adicionar"
            />
          )}
          {type === 'SECTIONS' && (
            <SectionsAutocomplete
              onFormSubmit={addItems}
              segment={segment}
              buttonTitle="adicionar"
            />
          )}
        </Grid>
        {addingPosition && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs="auto">
                <Typography>
                  Adicionando o item {addingPosition.current}/
                  {addingPosition.total}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CircularProgress size={20} color="primary" />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!(type === 'WARNING') && (
          <Grid item xs={12}>
            <ItemsList items={items} type={type} fetch={() => fetch(id)} />
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

export default BannersDetails
