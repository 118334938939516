import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useHistory } from 'react-router-dom'
import { TableContainer } from 'components'
import Card from 'components/Card'
import { makeStyles } from '@material-ui/core/styles'
import { TableCellHead } from 'components/TableCellHead'
import { Props } from './types'
import { FilterPanel } from './FilterPanel'
import { DeleteOutline, Delete } from '@material-ui/icons'
import { format } from 'date-fns'
import { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined'
import { IconButton, Grid, TextField, Tooltip } from '@material-ui/core'
import EmptyListFeedback from 'components/EmptyListFeedback'
import emptyError from 'assets/emptyError.svg'
import Spacer from 'components/Spacer'
import EditIcon from '@material-ui/icons/EditOutlined'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import { realApi } from 'services/api'
import { useSnackbar } from '@elentari/core'

const useStyles = makeStyles(theme => ({
  tabeleRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export const TableList = ({
  list,
  loading,
  totalCount,
  onDelete,
  onNotificate,
  fetch
}: Props) => {
  const [editing, setEditing] = useState<string>('')
  const [nameField, setNameField] = useState('')
  const [requestLoading, setRequestLoading] = useState(false)
  const [, snackbarActions] = useSnackbar()

  const classes = useStyles()
  const { push } = useHistory()

  const updateName = async (id: string) => {
    setRequestLoading(true)
    const res = await realApi.updateGroup(id, { name: nameField })
    if (res.ok) {
      snackbarActions.setSnackBar({
        message: 'Nome atualizado com sucesso!',
        severity: 'success'
      })
      setEditing('')
      if (fetch) fetch()
    } else {
      snackbarActions.setSnackBar({
        message: 'Houve um erro ao atualizar nome, tente novamente mais tarde.',
        severity: 'warning'
      })
      setEditing('')
    }
    setRequestLoading(false)
  }

  return (
    <>
      <FilterPanel />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Box fontWeight="fontWeightBold" component="span">
                    LISTA DE GRUPOS
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Total de grupos: {totalCount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer loading={loading} totalCount={totalCount}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellHead label="Nome" sort="name" />
                    <TableCellHead label="Data Cadastro" sort="createdAt" />
                    <TableCellHead label="" sort="" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.map(({ name, id, createdAt }) => {
                    return (
                      <>
                        <TableRow
                          key={id}
                          className={classes.tabeleRow}
                          hover
                          onClick={() => push(`group/${id}?groupId=${id}`)}
                        >
                          <TableCell>
                            {editing !== id ? (
                              name
                            ) : (
                              <TextField
                                onClick={e => e.stopPropagation()}
                                defaultValue={name}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                onChange={e => setNameField(e.target.value)}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {format(new Date(createdAt), 'dd/MM/yyyy')}
                          </TableCell>
                          <TableCell>
                            {editing !== id && (
                              <Grid container justify="flex-end" spacing={2}>
                                <Grid item xs="auto">
                                  <Tooltip title="Enviar notificação">
                                    <IconButton
                                      size="small"
                                      onClick={e => {
                                        e.stopPropagation()
                                        onNotificate(Number(id))
                                      }}
                                      aria-label="Adicionar notificação"
                                    >
                                      <AddAlertOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip title="Editar">
                                    <IconButton
                                      size="small"
                                      onClick={e => {
                                        e.stopPropagation()
                                        setEditing(id)
                                        setNameField(name)
                                      }}
                                      aria-label="Editar"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                  <Tooltip title="Deletar">
                                    <IconButton
                                      size="small"
                                      aria-label="Deletar"
                                      onClick={e => {
                                        e.stopPropagation()
                                        onDelete(Number(id))
                                      }}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            )}
                            {editing === id && (
                              <Grid container justify="flex-end" spacing={2}>
                                <Grid item xs="auto">
                                  <IconButton
                                    size="small"
                                    onClick={e => {
                                      e.stopPropagation()
                                      updateName(id)
                                    }}
                                    disabled={requestLoading}
                                    aria-label="Salvar"
                                  >
                                    <DoneIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs="auto">
                                  <IconButton
                                    onClick={e => {
                                      e.stopPropagation()
                                      setEditing('')
                                    }}
                                    aria-label="Cancelar"
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {list.length === 0 && !loading && (
          <>
            <EmptyListFeedback
              src={emptyError}
              title="LISTA VAZIA"
              description="Ainda não temos grupos cadastrados, clique no botão de adicionar e adicione um grupo!"
            />
            <Spacer y={4} />
          </>
        )}
      </Card>
    </>
  )
}
