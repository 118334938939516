import makeGlobal from '@elentari/core/utils/makeGlobal'
import { ApiResponse } from 'apisauce'
import { StoreActions } from '@elentari/core/types'
import qs from 'qs'
import { Page } from 'utils/types'

export interface Option {
  id: number | string
  name: string
}

interface State {
  loadedOptions: Option[]
}

interface Actions {
  loadOptions: (
    field: string,
    label: string,
    fetch: (params: qs.ParsedQs) => Promise<ApiResponse<Page<any>, Page<any>>>
  ) => void
  setLoadedOptions: (options: Option[]) => void
}

const initialState: State = {
  loadedOptions: []
}

const actions: StoreActions<State, Actions> = {
  loadOptions: store => async (field, label, fetch) => {
    const { data } = await fetch({ [field]: label })
    if (data) {
      const loadedOptions = await data.data.map((edge: any) => {
        return {
          name: edge.node.name,
          id: edge.node.id
        }
      })
      store.setState({ loadedOptions })
    }
  },
  setLoadedOptions: store => options => {
    store.setState({ loadedOptions: options })
  }
}

const useAutoComplete = makeGlobal<State, Actions>(initialState, actions)

export default useAutoComplete
