/* eslint-disable prefer-const */
import { Autocomplete } from '@material-ui/lab'
import { useState, useEffect } from 'react'
import { realApi } from 'services/api'
import {
  Chip,
  TextField,
  CircularProgress,
  Grid,
  Button
} from '@material-ui/core'
import { useField, useForm } from 'react-final-form-hooks'
import { ValidationErrors } from 'final-form'
import * as yup from 'yup'
import { BannerSegment } from 'types/generalTypes'

yup.setLocale({
  mixed: {
    default: 'Não é válido',
    required: 'O campo precisa estar preenchido'
  }
})

const schema = yup.object().shape({
  sections: yup
    .array()
    .min(1, 'Você precisa adicionar pelo menos um item')
    .required()
    .nullable()
})

export type BannerAutoCompleteOptions = {
  label: string
  value: string
}

type SectionsAutocompleteProps = {
  doubleLine?: boolean
  onFormSubmit: (data: BannerAutoCompleteOptions[]) => Promise<boolean>
  buttonTitle: string
  segment: BannerSegment['segment']
}

const SectionsAutocomplete = ({
  doubleLine,
  buttonTitle,
  segment,
  onFormSubmit
}: SectionsAutocompleteProps) => {
  const [options, setOptions] = useState<BannerAutoCompleteOptions[]>([
    { label: '', value: '' }
  ])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [autoCompleteKey, setAutoCompleteKey] = useState(
    `${new Date().getTime()}`
  )

  async function handleGetSections(value: string) {
    setLoading(true)
    const response = await realApi.getSections(segment, value, 50)
    if (response.ok && response.data) {
      const dataTreatment = response.data.map(item => ({
        label: item.descricao,
        value: item.codigoSecao.toString()
      }))
      setOptions(dataTreatment)
      return setLoading(false)
    }
    setOptions([{ label: '', value: '' }])
    return setLoading(false)
  }

  useEffect(() => {
    handleGetSections('')
  }, [])

  const onSubmit = async (values: {
    sections: BannerAutoCompleteOptions[]
  }) => {
    const success = await onFormSubmit(values.sections)
    if (success) {
      form.reset()
      setAutoCompleteKey(`${new Date().getTime()}`)
    }
  }

  const validate = (values: { sections: BannerAutoCompleteOptions[] }) => {
    try {
      schema.validateSync(values, { abortEarly: false })
    } catch (errors: any) {
      let formErrors: any = {}
      errors.inner.forEach((erro: ValidationErrors) => {
        formErrors[erro?.path] = erro?.message
      })
      return formErrors
    }
  }

  const { form, handleSubmit, values, pristine, submitting, initialValues } =
    useForm({
      onSubmit,
      validate
    })

  const sections = useField('sections', form)
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={doubleLine ? 12 : true}>
          <Autocomplete
            key={autoCompleteKey}
            multiple
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            loading={loading}
            autoHighlight
            disableCloseOnSelect
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={option => option.label}
            options={options}
            renderTags={(value: BannerAutoCompleteOptions[], getTagProps) =>
              value.map((option: BannerAutoCompleteOptions, index: number) => (
                <Chip
                  key={`Option ${index}`}
                  variant="outlined"
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(event, options) => sections.input.onChange(options)}
            onInputChange={(e, value) => handleGetSections(value)}
            renderInput={params => (
              <TextField
                {...sections.input}
                {...params}
                size="small"
                fullWidth
                label="Selecione as seções por código ou nome"
                variant="outlined"
                color="primary"
                rowsMax={1}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                helperText={
                  sections.meta.touched &&
                  sections.meta.invalid &&
                  sections.meta.error
                }
                error={sections.meta.touched && sections.meta.invalid}
              />
            )}
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            data-testid="salvar"
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={pristine || submitting}
            size="large"
          >
            {buttonTitle}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default SectionsAutocomplete
