import { useQueryParams } from '@elentari/core'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from 'components/Card'
import { Input } from 'components/Input'
import { useState } from 'react'
import { Form } from 'react-final-form'
import { formatCNPJ, removeFormat } from 'utils/formatString'
import { SubmitValues } from './types'

type UserQueryFilter = Record<string, any>
export const UserFilter = () => {
  const { push, initialValues } = useQueryParams<UserQueryFilter>()
  const [cnpj, setCnpj] = useState<string>('')

  const handleSubmitFilter = (values: SubmitValues) => {
    return push({
      ...values,
      page: 1,
      size: 10
    })
  }
  const clear = () => {
    setCnpj('')
    push({ status: 'PENDING', cnpj: null })
  }
  const format = (initialValues: UserQueryFilter) => {
    return {
      ...initialValues
    }
  }
  return (
    <Card>
      <Form
        initialValues={format(initialValues)}
        onSubmit={values => {
          const cnpjUpdated = { ...values, cnpj: removeFormat(cnpj) }
          handleSubmitFilter(cnpjUpdated)
        }}
        render={({ values: val, handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <Box fontWeight="fontWeightBold">FILTRO</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="cnpj"
                    label="CNPJ"
                    value={cnpj}
                    onChange={e =>
                      e.target.value.length <= 18
                        ? setCnpj(formatCNPJ(e.target.value))
                        : null
                    }
                  />
                </Grid>
                <Grid item xs="auto">
                  <Button variant="outlined" color="secondary" onClick={clear}>
                    LIMPAR
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    type="submit"
                  >
                    BUSCAR
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </Card>
  )
}
